.btn {
  width: 100%;
  height: 48px;
  text-align: center;
  display: inline-block;
  border-radius: 0.5rem;
  font-family: 'Neue Plak Text', sans-serif;
  font-weight: 700;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: all 0.25s;

  &--green,
  &--not-found {
    background-color: $mainGreen;
    color: $mainText;

    &:hover,
    &:active {
      background-color: darken($mainGreen, 4%);
    }
  }

  &--not-found {
    width: fit-content;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
  }

  &--light {
    background-color: #fff;
    color: $mainText;
    border: 1px solid #e7e7e7;

    &:hover,
    &:active {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) inset;
    }
  }

  &--login {
    background-color: #fff;
    color: #1f2125;
    border: 1px solid #e7e7e7;

    &:hover,
    &:active {
      background-color: darken($mainGreen, 4%);
    }
  }
}
