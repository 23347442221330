.back-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 4px;
  height: 30px;
  width: 40px;
  background-color: $mainGreen;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  transition: opacity 0.5s ease-in-out;

  &--visible {
    opacity: 1;
  }

  img {
    width: 15px;
  }

  &:hover {
    opacity: 0.8;
  }
}
