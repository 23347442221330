.loader-async {
  border: 10px solid #f3f3f3;
  border-top: 10px solid $mainGreen;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;

  transform: translateX(-50%);
  margin-left: 50%;
  margin-top: 30vh;
  margin-bottom: 10rem;

  @include atMedium {
    width: 100px;
    height: 100px;
  }
}

@keyframes spin {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }
  100% {
    transform: translateX(-50%) rotate(360deg);
  }
}
