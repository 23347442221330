.position {
  @include atMedium {
    margin-top: 10rem;
  }

  @include atLarge {
    margin-top: 12rem;
  }

  @include atX-Large {
    margin-top: 14rem;
  }

  &__right {
    @include atMedium {
      padding-left: 2rem;
    }
    @include atLarge {
      padding-left: 4rem;
    }
    @include atX-Large {
      padding-left: 6rem;
    }
  }

  &__title {
    text-align: center;
    margin: 0;
    margin-top: 4rem;
    margin-bottom: 2rem;

    @include atMedium {
      margin-top: 0;
      font-size: 2.25rem;
      text-align: left;
    }
  }

  &__info-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $textMuted;
    margin-top: 1rem;

    @include atSmall {
      justify-content: space-around;
    }

    @include atMedium {
      justify-content: flex-start;
    }
  }

  &__info-icon {
    display: flex;
    align-items: center;

    .icon {
      transform: scale(0.9);
      margin-right: 0.5rem;
    }

    @include atMedium {
      margin-right: 2rem;
    }

    @include atLarge {
      margin-right: 3rem;
    }

    @include atX-Large {
      margin-right: 4rem;
    }
  }

  .position__descriptions {
    margin-bottom: 2rem;

    @include atLarge {
      margin-bottom: 5rem;
    }
  }

  &__description {
    &:first-child {
      margin-top: 2rem;
    }
    text-align: justify;
    line-height: 1.6;
    margin-top: 1rem;

    @include atLarge {
      font-size: 1.25rem;
      line-height: 1.8;
      margin-bottom: 0.5rem;
    }
  }

  &__list-section {
    margin-bottom: 2rem;

    @include atSmall {
      margin-bottom: 3.5rem;
    }

    @include atLarge {
      margin-bottom: 5rem;
    }
  }

  .intro {
    color: $mainGreen;
    font-size: 1.2rem;
    margin-top: 1.5rem;

    @include atSmall {
      font-size: 1.35rem;
    }

    @include atLarge {
      font-size: 1.5rem;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
    // padding-left: 1rem;
    margin-left: -5px;

    li {
      display: flex;
      align-items: flex-start;
      margin-bottom: 0.25rem;

      @include atMedium {
        margin-bottom: 0.5rem;
      }

      &::before {
        content: '';
        display: inline-block;
        height: 24px;
        min-width: 38px;
        background-image: url('../../assets/images/position-page/checkmark.svg');
        background-repeat: no-repeat;
        background-position: center center;
        padding-top: 32px;
        transform: scale(0.8);

        @include atSmall {
          padding-top: 40px;
          transform: scale(1);
        }

        @include atLarge {
          padding-top: 45px;
        }
      }

      span {
        display: inline-block;
        vertical-align: top;
        padding-top: 4px;
        margin-left: 0.5rem;
        font-weight: 300;
        font-size: 0.8rem;
        line-height: 1.6;
        flex: 1;

        @include atSmall {
          font-size: 1rem;
        }

        @include atLarge {
          font-size: 1.2rem;
          line-height: 1.8;
          margin-left: 1.5rem;
          padding-top: 6px;
        }
      }
    }
  }

  .intro.intro--clauses {
    color: red;
  }

  &__list--clauses {
    margin-left: -8px;
    li {
      &::before {
        height: 45px;
        min-width: 45px;
        background-image: url('../../assets/images/position-page/exclamation-mark.svg');
        transform: scale(0.45);

        @include atSmall {
          padding-top: 40px;
          transform: scale(0.5);
        }

        @include atLarge {
          padding-top: 45px;
        }
      }

      span {
        margin-left: 0.2rem;

        @include atLarge {
          margin-left: 1.2rem;
        }
      }
    }
  }

  &__ndru {
    margin-top: 3rem;

    h2 {
      text-align: left;
      font-weight: 400;

      font-size: 1.25rem;

      @include atSmall {
        font-size: 1.5rem;
      }
    }

    p {
      text-align: justify;
      line-height: 1.6;
      margin-top: 1rem;

      @include atLarge {
        font-size: 1.25rem;
        line-height: 1.8;
        margin-bottom: 0.5rem;
      }
    }
  }

  &__careers-btn {
    margin-top: 3rem;
    color: white;
    background-color: #2f80ed;

    &:hover,
    &:active {
      background-color: darken(#2f80ed, 7%);
    }

    @include atSmall {
      width: 250px;
      margin-left: auto;
      margin-right: auto;
    }

    @include atLarge {
      margin-top: 5rem;
    }
  }

  &__benefits {
    margin-top: 5rem;
    margin-bottom: 5rem;

    @include atMedium {
      margin-top: 8rem;
    }

    h2 {
      color: $mainGreen;
      text-align: center;
      margin-bottom: 2rem;
      font-size: 26px;

      @include atMedium {
        font-size: 2.25rem;
      }

      @include atLarge {
        margin-bottom: 3.5rem;
      }
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &__card {
    width: 239px;
    height: 341px;
    width: 80%;
    height: 380px;
    border-radius: 24px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 48px;
    /* or 150% */

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    color: #ffffff;

    margin: auto;
    margin-bottom: 1rem;
    margin-top: 1rem;

    @media (min-width: 500px) {
      width: 192px;
      height: 272px;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    @media (min-width: 685px) {
      width: 220px;
      height: 272px;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    @include atMedium {
      width: 134px;
      height: 190px;
      margin-left: 1rem;
      margin-right: 1rem;
      font-size: 1.5rem;
    }

    @media (min-width: 845px) {
      width: 150px;
      height: 210px;
    }

    @media (min-width: 925px) {
      width: 160px;
      height: 225px;
    }

    @media (min-width: 975px) {
      width: 175px;
      height: 240px;
    }

    @include atLarge {
      width: 191px;
      height: 272px;
      font-size: 2rem;
    }

    @include atX-Large {
      width: 239px;
      height: 341px;
      margin-left: 2rem;
      margin-right: 2rem;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
  }
}
