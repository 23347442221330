.blog-post {
  &__content {
    margin-bottom: 5rem;
  }

  &__flag {
    margin: 0 auto;
    margin-top: 7rem;
    width: 1282px;
    max-width: 90%;

    @include atLarge {
      font-size: 36px;
      margin-top: 10rem;
    }
  }

  &__category {
    color: $mainGreen;
    text-align: center;
    margin: 0;
    margin-top: 2rem;
    font-weight: 600;
    font-size: 18px;

    @include atLarge {
      font-size: 24px;
      margin-top: 5rem;
    }
  }

  &__title {
    text-align: center;
    font-size: 24px;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 1.5rem;
    line-height: 1.4;

    @include atLarge {
      font-size: 32px;
      margin-top: 2rem;
    }
  }

  &__meta {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    margin-top: 1.5rem;

    @include atLarge {
      font-size: 20px;
      margin-top: 2rem;
    }
  }

  &__author {
    color: $mainGreen;
  }

  &__hero-img {
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 27px;
    overflow: hidden;
    margin: 2rem auto 0 auto;
    width: 1282px;
    max-width: 98%;

    @include atSmall {
      height: 350px;
    }

    @include atMedium {
      height: 490px;
      max-width: 90%;
    }

    @include atLarge {
      margin-top: 4rem;
    }
  }

  &__paragraph {
    h3 {
      color: $mainGreen;
      margin-top: 2rem;
      line-height: 1.6;

      @include atLarge {
        font-size: 24px;
        margin-top: 3rem;
      }
    }

    p {
      margin-bottom: 1rem;
      line-height: 1.6;

      @include atLarge {
        font-size: 18px;
        margin-bottom: 1rem;
        line-height: 1.8;
      }
    }
  }

  &__img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 27px;
    overflow: hidden;
    height: 200px;
    margin: 0 auto;

    @include atMedium {
      height: 300px;
    }

    @include atLarge {
      width: 837px;
      height: 490px;
    }
  }
}
