.icon {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
}

.icon:first-child {
  left: -15px;
}
.icon:last-child {
  right: -15px;
  justify-content: flex-end;
}
.icon i {
  width: 25px;
  height: 25px;
  font-size: 0.6rem;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #efedfb;
  transition: all 0.25s;
}
.icon i:hover {
  background: #72d66f;
  transform: scale(1.1) translateX(2px);
  color: #fff;
}
.icon:first-child i {
  margin-left: 15px;
}
.icon:last-child i {
  margin-right: 15px;
}
.icon:last-child i:hover {
  transform: scale(1.1) translateX(-2px);
}

.wrapper {
  position: relative;
  padding: 0;
  overflow-x: hidden;
  max-width: 1000px;
  background: #fff;
  border-radius: 13px;
}

.tabs-box {
  display: flex;
  gap: 12px;
  list-style: none;
  overflow-x: hidden;
  scroll-behavior: smooth;
  padding: 0 2.5rem;
}

@media screen and (min-width: 600px) {
  .icon {
    opacity: 0;
    transition: all 0.25s;
  }
  .wrapper:hover .icon {
    opacity: 1;
  }

  .tabs-box {
    padding: 0.5rem 0;
    transition: all 0.25s;
  }

  .wrapper:hover .tabs-box {
    padding: 0.5rem 2.5rem;
  }
}
