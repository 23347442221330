.our-values {
  text-align: center;
  .section__title {
    color: hsl(0, 0%, 31%);
  }

  &__grid {
    @include atSmall {
      display: flex;
      flex-wrap: wrap;
      gap: 0.25rem;
      justify-content: center;
      align-items: center;
    }

    @include atLarge {
      gap: 2rem;
    }
  }

  &__item {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: #72d66f33;
    border-radius: 24px;
    overflow: hidden;
    max-width: 350px;

    align-self: flex-start;

    @include atSmall {
      flex: 0 0 48%;
      align-self: flex-start;
    }

    @include atLarge {
      flex: 0 0 30%;
      margin-bottom: 2rem;
      min-height: 235px;
    }
  }

  &__header {
    padding: 0.2rem 0;
    display: flex;
    align-items: center;
  }

  &__image {
    display: inline-block;
    height: 50px;
    width: 50px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-right: 26px;

    @include atSmall {
      margin-right: 15px;
    }

    @include atLarge {
      margin-right: 26px;

      height: 60px;
      width: 60px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    color: #1f2125;
    text-align: left;
    @include atMedium {
      font-size: 24px;
    }
  }

  &__description {
    font-size: 15px;
    line-height: 25px;
    text-align: left;
    padding: 0.2rem 0;
    display: flex;

    @include atLarge {
      padding: 0.4rem 0;
      font-size: 17px;
      padding-top: 1rem;
    }
  }
}

.section.our-values {
  padding-top: 1rem;
}
