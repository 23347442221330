.locations {
  .location {
    img {
      display: block;
      border-radius: 8px;
      width: 100%;
      object-fit: contain;

      @include atLarge {
        border-radius: 16px;
      }
    }

    .img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 120px;

      @media (min-width: 440px) {
        height: 140px;
      }

      @media (min-width: 500px) {
        height: 160px;
      }

      @media (min-width: 575px) {
        height: 170px;
      }

      @include atMedium {
        height: 240px;
      }

      @include atLarge {
        height: 265px;
      }

      @include atX-Large {
        height: 300px;
      }
    }

    h5 {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
      margin: 0;
      margin-top: 0.5rem;

      @include atLarge {
        font-size: 1.25rem;
        margin-top: 1rem;
      }
    }

    .location__address {
      font-size: 0.75rem;
      line-height: 1.125rem;
      color: $textMuted;
      margin: 0;

      @include atLarge {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-top: 0.25rem;
      }
    }

    .location__stars {
      margin-top: 0.35rem;
      display: flex;
      align-items: center;

      svg {
        width: 0.8125rem;
        margin-right: 0.1875rem;
        @include atLarge {
          width: 1.25rem;
          margin-right: 0.25rem;
        }
      }

      svg:not(.empty) {
        color: #ffda32;
      }

      .rating-text {
        font-size: 0.75rem;
        margin-left: 0.3125rem;
        @include atLarge {
          font-size: 0.875rem;
          margin-left: 0.875rem;
        }
      }
    }
  }
}
