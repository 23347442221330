.site-header {
  height: 4.5rem;
  overflow: hidden;
  z-index: 1000;
  background-color: $mainBg;
  position: absolute;
  width: 100%;
  top: 0;
  display: flex;
  transition: box-shadow 0.25s;

  &--shadow {
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    margin-left: -4px;

    width: 50px;
    height: 70px;
    img {
      width: 98%;
    }
  }

  &__toggler {
    display: flex;
    align-items: center;
    cursor: pointer;

    .toggler__label {
      margin-right: 1.1875rem;
      font-size: 1rem;
      user-select: none;
      margin-top: 3px;
    }

    .toggler__icon {
      width: 18px;
      height: 16px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 18px;
        top: 0;
        left: 0;
        height: 2px;
        background-color: $mainText;
        transform-origin: 0 0;
        transition: transform 0.3s ease-out;
      }

      .middle {
        position: absolute;
        width: 18px;
        top: 7px;
        left: 0;
        height: 2px;
        background-color: $mainText;
        transform-origin: 0 50%;
        transition: all 0.3s ease-out;
      }

      &::after {
        content: '';
        position: absolute;
        width: 18px;
        bottom: 0;
        left: 0;
        height: 2px;
        background-color: $mainText;
        transform-origin: 0 100%;
        transition: transform 0.3s ease-out;
      }
    }

    &--close-x {
      .toggler__icon::before {
        transform: rotate(45deg) scaleX(1.15);
      }

      .middle {
        opacity: 0;
        transform: scaleX(0);
      }

      .toggler__icon::after {
        transform: rotate(-45deg) scaleX(1.15);
      }
    }
  }

  &--is-expanded {
    position: fixed;

    .site-header__search {
      opacity: 0;
    }
  }

  &__menu-content {
    background-color: $mainBg;
    display: none;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.61, 1, 0.88, 1);
    position: fixed;

    top: 4.5rem;
    width: 100%;
    height: calc(100vh - 4.5rem);
    transform: scale(0.9);
    left: 100%;

    @include atMedium {
      height: auto;
      width: 14.5rem;
      right: 1rem;
      top: calc(4.5rem + 1rem);
      border-radius: 0.25rem;
      transform: scale(1);
    }

    @include atLarge {
      height: auto;
      width: 15.5rem;
    }

    @media only screen and (min-width: 1400px) {
      right: calc((50vw - 87.5rem / 2));
    }

    &--is-visible {
      opacity: 1;
      z-index: 1000;

      left: 0;
      transform: scale(1);

      @include atMedium {
        display: block;
        border: 1px solid #e7e7e7;
        left: auto;
      }
    }

    .menu-content__header {
      user-select: none;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-top: 80px;
      margin-bottom: 3rem;

      @include atMedium {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        display: none;
      }
    }
  }

  &__hr {
    height: 1px;
    background-color: #e7e7e7;
    border: none;
    position: absolute;
    left: 0;
    width: 100%;
  }
}
