.dtr2 {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  margin-top: 30px;

  @media only screen and (min-width: 335px) {
    font-size: 22px;
    line-height: 30px;
  }

  @media only screen and (min-width: 375px) {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 40px;
  }

  @include atMedium {
    font-size: 27px;
    margin-bottom: 16px;
    line-height: 34px;
    margin-top: 3rem;
  }

  @media only screen and (min-width: 950px) {
    margin-top: 3rem;
    margin-bottom: 25px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 40px;
    line-height: 48px;
  }

  @media only screen and (min-width: 1120px) {
    margin-top: 3.5rem;
  }

  @include atX-Large {
    margin-top: 6rem;
    margin-bottom: 30px;
  }

  @media only screen and (min-width: 1400px) {
    margin-top: 8rem;
    margin-bottom: 35px;
  }

  @media only screen and (min-width: 1535px) {
    margin-top: 12rem;
  }
  @media only screen and (min-width: 1800px) {
    margin-top: 14rem;
  }

  &__start,
  &__end {
    display: block;
    text-align: center;
    word-wrap: none;
    @include atMedium {
      text-align: left;
    }

    br {
      display: none;

      @media only screen and (min-width: 335px) {
        display: block;
      }
    }
  }

  &__texts {
    overflow: hidden;
    background-color: $mainGreen;
    position: relative;
    margin: auto;
    width: 226px;
    height: 28px;

    @media only screen and (min-width: 335px) {
      height: 30px;
      width: 241px;
    }
    @media only screen and (min-width: 375px) {
      width: 330px;
      height: 38px;
    }

    @include atMedium {
      width: 295px;
      height: 34px;
      margin: 0;
      margin-right: auto;
    }

    @media only screen and (min-width: 992px) {
      height: 48px;
      width: 426px;
    }

    ul {
      margin: 0;
      padding: 0;
      width: 100%;
      list-style: none;
      position: absolute;
      height: 100%;
      animation-name: animHeading2;
      animation-duration: 12s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    li {
      text-align: center;
    }
  }
}

@keyframes animHeading2 {
  0% {
    top: 0;
  }

  18% {
    top: 0;
  }

  20% {
    top: -100%;
  }

  38% {
    top: -100%;
  }

  40% {
    top: -200%;
  }

  58% {
    top: -200%;
  }

  60% {
    top: -300%;
  }

  78% {
    top: -300%;
  }

  80% {
    top: -400%;
  }

  100% {
    top: -400%;
  }
}
