.terms-of-service {
  margin-top: 6rem;
  margin-bottom: 0;

  @include atMedium {
    margin-top: 8rem;
    margin-bottom: 2rem;
  }

  h1 {
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 1.4rem;
    text-align: center;
  }
  h2 {
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 18pt;
    font-size: 1.2rem;
    text-align: center;
  }
  .p,
  p {
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
    margin: 0pt;
  }
  h3 {
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10.5pt;
  }
  .s1 {
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
  }
  .s2 {
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
  }
  .s3 {
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
  }
  .s6 {
    color: #3b4043;
    font-family: 'Gill Sans MT', sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
  }
  .s7 {
    color: #333;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
  }
  .s8 {
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
  }
  .a,
  a {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
  }
  li {
    display: block;
  }
  #l1 {
    padding-left: 0pt;
    counter-reset: c1 1;
  }
  #l1 > li > *:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal) '. ';
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10.5pt;
  }
  #l1 > li:first-child > *:first-child:before {
    counter-increment: c1 0;
  }
  #l2 {
    padding-left: 0pt;
    counter-reset: d1 1;
  }
  #l2 > li > *:first-child:before {
    counter-increment: d1;
    content: counter(d1, decimal) '. ';
    color: black;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
  }
  #l2 > li:first-child > *:first-child:before {
    counter-increment: d1 0;
  }
  #l3 {
    padding-left: 0pt;
  }
  #l3 > li > *:first-child:before {
    content: '- ';
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
  }
  #l4 {
    padding-left: 0pt;
  }
  #l4 > li > *:first-child:before {
    content: '- ';
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
  }
  #l5 {
    padding-left: 0pt;
  }
  #l5 > li > *:first-child:before {
    content: '- ';
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
  }
  #l6 {
    padding-left: 0pt;
  }
  #l6 > li > *:first-child:before {
    content: '- ';
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
  }
  li {
    display: block;
  }
  #l7 {
    padding-left: 0pt;
  }
  #l7 > li > *:first-child:before {
    content: '- ';
    color: #333;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10.5pt;
  }
  table,
  tbody {
    vertical-align: top;
    overflow: visible;
  }
}
