.dtr3 {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 28px;
  margin-top: 30px;
  text-align: center;

  @media only screen and (min-width: 335px) {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 25px;
  }

  @media only screen and (min-width: 375px) {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 40px;
  }

  @include atSmall {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 40px;
  }

  @include atMedium {
    font-size: 26px;
    line-height: 32px;
    text-align: left;
    margin-bottom: 16px;
    margin-top: 40px;
  }

  @media only screen and (min-width: 820px) {
    margin-top: 70px;
  }

  @media only screen and (min-width: 950px) {
    margin-top: 80px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 2.5rem;
    margin-top: 60px;
  }

  @media only screen and (min-width: 1075px) {
    margin-top: 80px;
  }

  @media only screen and (min-width: 1150px) {
    margin-top: 100px;
  }

  @include atX-Large {
    margin-top: 140px;
  }

  @media only screen and (min-width: 1400px) {
    margin-top: 160px;
  }

  @media only screen and (min-width: 1570px) {
    margin-top: 180px;
  }

  @media only screen and (min-width: 1630px) {
    margin-top: 225px;
  }

  @media only screen and (min-width: 1920px) {
    margin-top: 240px;
  }

  &__start,
  &__end {
    display: block;
    text-align: center;
    word-wrap: none;
    @include atMedium {
      text-align: left;
    }
  }

  &__start {
    display: inline;
  }

  &__texts {
    overflow: hidden;
    background-color: $mainGreen;
    position: relative;
    margin: auto;
    width: 180px;
    height: 28px;
    display: inline-block;
    top: 8px;

    @media only screen and (min-width: 335px) {
      height: 30px;
    }
    @media only screen and (min-width: 375px) {
      height: 34px;
      width: 210px;
    }

    @include atSmall {
      height: 40px;
      width: 260px;
    }

    @include atMedium {
      width: 210px;
      height: 32px;
    }

    @media only screen and (min-width: 992px) {
      height: 48px;
      width: 320px;
    }

    ul {
      margin: 0;
      padding: 0;
      width: 100%;
      list-style: none;
      position: absolute;
      height: 100%;
      animation-name: animHeading3;
      animation-duration: 14s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    li {
      text-align: center;
    }
  }
}

@keyframes animHeading3 {
  0% {
    top: 0;
  }

  14% {
    top: 0;
  }

  16% {
    top: -100%;
  }

  30% {
    top: -100%;
  }

  32% {
    top: -200%;
  }

  46% {
    top: -200%;
  }

  48% {
    top: -300%;
  }

  62% {
    top: -300%;
  }

  64% {
    top: -400%;
  }

  78% {
    top: -400%;
  }

  80% {
    top: -500%;
  }

  100% {
    top: -500%;
  }
}
