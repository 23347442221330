.categories {
  display: flex;
  justify-content: space-between;

  @include atSmall {
    justify-content: space-around;
  }

  @include atMedium {
    gap: 3rem;
    justify-content: center;
  }

  @include atLarge {
    justify-content: space-between;
  }

  .category {
    background-color: $categoryBg;
    padding: 1rem;
    width: 6.125rem;
    height: 6.125rem;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    flex-direction: column;

    border-radius: 0.5em;

    box-shadow: 0px 10.3731px 20.7463px 10.3731px
      rgba(32, 33, 43, 0.078813);

    transition: 0.25s;

    &:hover,
    &:focus {
      cursor: pointer;
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }

    @include atMedium {
      width: 10rem;
      height: 8rem;
    }

    @include atLarge {
      flex: 1;
      height: auto;
      padding: 2rem;

      img {
        width: 35%;
      }
    }

    br {
      @include atLarge {
        display: none;
      }
    }

    span {
      position: absolute;
      text-align: center;
      line-height: 1.5rem;
      bottom: -55px;

      @include atLarge {
        position: static;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.75rem;
        margin-top: 1.2rem;
      }
    }

    &:nth-of-type(2) {
      img {
        margin-left: 5px;
      }
    }
  }
}

.categories {
  &__repair-shops-list {
    @include atLarge {
      justify-content: center;
      gap: 0;
    }

    .category {
      box-shadow: none;
      width: 6.125rem;
      padding-bottom: 0;

      &:hover,
      &:focus,
      &.active {
        cursor: pointer;

        box-shadow: none;
        color: $mainGreen;

        svg {
          rect,
          ellipse,
          path {
            transition: 0.25s;
            stroke: $mainGreen !important;
          }

          mask ~ path {
            transition: 0.25s;

            fill: $mainGreen !important;
          }
        }
      }

      .label {
        bottom: -40px;
        transition: none;
      }
    }
  }
}
