@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.hero-service-providers {
  display: flex;
  flex-direction: column;

  @include atLarge {
    flex-direction: row;

    align-items: center;
    margin-top: 2rem;
  }

  @include atX-Large {
    margin-top: 4rem;
  }

  &__texts {
    margin-bottom: 3.125rem;
    max-width: 39.5rem;

    @include atLarge {
      width: 55%;
    }
  }

  &__title {
    color: $mainGreen;
    font-size: 1.625rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;

    @include atMedium {
      font-size: 2.25rem;
    }

    @include atLarge {
      text-align: left;
    }
  }

  &__subtitle {
    font-size: 1.125rem;
    line-height: 1.4;
    text-align: center;
    margin: 0;
    margin-bottom: 1rem;

    @include atMedium {
      font-size: 1.5rem;
    }

    @include atLarge {
      text-align: left;
    }
  }

  &__buttons {
    @include atMedium {
      margin-top: 2rem;
    }
  }

  &__images {
    @include atSmall {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin-right: -15px;
      margin-left: 15px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 2rem;
    }

    @include atLarge {
      transform: none;
      left: auto;
      width: 45%;
      margin: 0;
    }
  }

  .left-side {
    display: none;
    @include atSmall {
      display: block;
      width: 50%;
    }

    @include atLarge {
      display: none;
    }
  }

  &__image-container {
    position: relative;
    max-width: 315px;
    margin: auto;

    @include atSmall {
      width: 50%;
    }

    @include atMedium {
      width: 45%;
    }

    @include atLarge {
      position: absolute;
      right: 0;
    }

    .phone {
      display: block;
      width: 100%;
      border-radius: 18px;

      @include atLarge {
        width: 200px;
        border-radius: 12px;
      }

      @include atX-Large {
        width: 220px;
        border-radius: 12px;
      }
    }
  }

  .caption {
    width: 200px;
    font-size: 1.15rem;
    line-height: 1.4;
    color: $mainGreen;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;

    @media (min-width: 350px) {
      bottom: 125px;
    }

    @include atSmall {
      left: -70%;
      bottom: 45px;
      font-size: 1.5rem;
      width: 240px;
      text-align: right;
    }

    @include atMedium {
      width: 290px;
    }

    @include atLarge {
      font-size: 1.5rem;
      max-width: 290px;
      font-weight: normal;
      left: -95%;
      bottom: 8px;
    }

    @include atX-Large {
      left: -88%;
    }
  }

  .arrow {
    display: block;
    position: absolute;
    left: 18%;
    transform: translateX(-50%) rotate(-10deg);
    bottom: 160px;

    @media (min-width: 350px) {
      bottom: 199px;
      transform: translateX(-50%) rotate(-10deg) scaleY(1.3);
    }

    @include atSmall {
      transform: none;
      height: 140px;
      left: -25%;
      bottom: 118px;
    }

    @include atMedium {
      height: 150px;
      bottom: 130px;
    }

    @include atLarge {
      height: 135px;
      bottom: 78px;
    }
  }

  margin-bottom: 2rem;

  @include atMedium {
    margin-bottom: 4rem;
  }

  @include atLarge {
    margin-bottom: 6rem;
  }

  @include atX-Large {
    margin-bottom: 8rem;
  }
}
