.site-footer {
  &__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      display: inline-block;
      margin-right: 1rem;
    }

    strong {
      font-size: 1.25rem;
    }
  }

  &__social {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-around;

    a {
      transition: 0.25s;
      border-radius: 10px;
      padding: 0.5rem;
      height: 40px;
      width: 40px;

      &:hover,
      a:focus {
        svg path {
          fill: $mainGreen;
        }
      }
    }

    @include atMedium {
      justify-content: flex-start;

      a {
        margin-right: 1rem;
      }
    }

    @include atLarge {
      a {
        margin-right: 1.5rem;
      }
    }
  }

  &__block {
    margin-left: 0.5rem;

    @include atMedium {
      margin-left: 0;
    }

    h3 {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 600;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      list-style: none;
      margin-bottom: 1.25rem;
    }

    a {
      font-size: 0.875rem;
      line-height: 1.4;

      @include atLarge {
        font-size: 1rem;
      }

      &:hover:not(.badge) {
        text-decoration: underline;
        text-underline-offset: 5px;
        // text-decoration-color: #000;
        text-decoration-color: $textDarkgrey;
      }
    }

    &--badge {
      li {
        margin-bottom: 0.5rem;

        @include atMedium {
          margin-bottom: 0.6rem;
        }
      }

      a.badge {
        padding: 0.6rem 1.3rem;

        @include atMedium {
          width: 8rem;
          padding: 0.5rem 1rem;
        }
      }
    }
  }

  &__hr {
    height: 1px;
    background-color: #e7e7e7;
    border: none;
  }

  &__copyright {
    margin: 0;
    font-size: 0.75rem;

    a:hover {
      text-decoration: underline;
    }
  }
}
