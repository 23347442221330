.get-in-touch {
  background-color: #1F2125;
  color: #fff;

  &__contact {
    @include atMedium {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    @include atLarge {
      margin-top: -26px;
    }

    h2 {
      line-height: 2rem;
      font-size: 1.5rem;
      margin-top: 0;
      margin-bottom: 1.5rem;

      @include atMedium {
        font-size: 2.25rem;
        line-height: 2.5rem;
        margin-bottom: 2.5rem;
      }
    }
  
    p {
      color: #e7e7e7;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-top: 0;
      margin-bottom: 1rem;

      @include atMedium {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 1.3rem;
      }
    }
  }

  &__form-container {
    margin-top: 0.6875rem;
    @include atMedium {
      margin-top: 0;
    }

    label {
      display: none;
    }

    input,
    textarea {
      width: 100%;
      border-radius: 0.5rem;
      padding: 1rem;
      border: none;
      background-color: rgba(255, 255, 255, 0.08);

      color: #e7e7e7;
    }

    ::placeholder,
    ::-ms-input-placeholder {
      color: #e7e7e7;
    }

    textarea {
      height: 140px;
    }

    .form-group {
      margin-bottom: 1.5rem;

      @include atLarge {
        margin-left: auto;
        width: 440px;
      }
    }

    .btn {
      @include atLarge {
        width: 240px;
        margin-left: auto;
      }
    }
  }
}