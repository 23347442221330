html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  font-family: 'Neue Plak Text', sans-serif;
  color: #1f2125;
  background-color: $mainBg;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

a {
  color: $mainText;
  text-decoration: none;
}

main {
  margin-top: 4.5rem;
}

.col-12--no-padding {
  @media screen and (max-width: 600px) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
