.header-details {
  font-size: 1rem;
  width: 100%;
  position: relative;

  .summary-title {
    user-select: none;

    &--font-weight-normal {
      font-weight: normal;
    }
  }

  &[open] .summary-title--font-weight-normal {
    font-weight: bold;
  }

  &:hover {
    cursor: pointer;
  }

  .summary-content {
    cursor: default;

    margin-top: 1rem;
  }

  summary {
    list-style: none;
    font-weight: 600;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.8;
    }

    &:focus {
      outline: none;
    }

    &:hover .summary-chevron-up svg {
      opacity: 0.8;
    }
  }

  .summary-chevron-up svg {
  }

  .summary-chevron-up,
  .summary-chevron-down {
    pointer-events: none;
    position: absolute;
    top: -2px;
    right: -8px;
    background: #ffffff;

    svg {
      display: block;
    }
  }

  summary::-webkit-details-marker {
    display: none;
  }

  .link {
    padding-left: 0.5rem;
    margin-top: 0.75rem;
    justify-content: flex-start;

    &:hover {
      text-decoration: underline !important;
    }
  }

  &[open] summary ~ * {
    animation: sweep 0.5s ease-in-out;
  }
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-left: -10px;
  }
  100% {
    opacity: 1;
    margin-left: 0px;
  }
}
