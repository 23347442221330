.category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  cursor: pointer;
  transition: all 0.25s;
  white-space: nowrap;
  font-size: 14px;
}

.category:hover,
.active {
  color: #72d66f;
}
.active {
  color: #72d66f;
}

.name {
  padding-bottom: 3px;
  border-bottom: 1px solid transparent;
}

.active .name {
  border-color: #72d66f;
}

@media screen and (min-width: 600px) {
  .category {
    font-size: 16px;
  }
}

@media screen and (min-width: 900px) {
  .category {
    font-size: 18px;
  }
}

@media screen and (min-width: 992px) {
  .category {
    font-size: 20px;
    margin-right: 1rem;
  }
}
