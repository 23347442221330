.team {
  text-align: center;
  .section__title {
    color: #4f4f4f;
  }

  &__grid {
    @include atMedium {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 1.5rem;
    }
  }

  &__item {
    border-radius: 8px;
    overflow: hidden;
    background-color: $mainGreen;
    margin-bottom: 2.5rem;
  }

  &__header {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
    background-color: $mainGreen;
  }

  &__image {
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    img {
      width: 100%;
      display: block;
    }
  }

  &__name {
    background-color: #fff;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
  }
}

.section.team {
  padding-top: 1rem;
}
