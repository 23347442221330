.hero2 {
  &__row {
    display: flex;
    flex-direction: column;

    @include atMedium {
      flex-direction: row;
      margin-bottom: 5vw;
    }
  }

  &__column1 {
    margin-bottom: 2.5rem;

    @include atMedium {
      margin-bottom: 0;
      width: 47%;
    }

    @include atLarge {
      margin-bottom: 0;
      width: 500px;
    }
  }

  &__column2 {
    @include atMedium {
    }

    @include atLarge {
    }

    @include atX-Large {
    }
  }

  &__image {
    img,
    svg {
      display: block;
      width: 100%;
      margin: auto;

      @media only screen and (min-width: 1440px) {
        width: 102%;
      }
      @media only screen and (min-width: 1460px) {
        width: 103%;
      }
      @media only screen and (min-width: 1480px) {
        width: 104%;
      }
      @media only screen and (min-width: 1490px) {
        width: 104.5%;
      }
      @media only screen and (min-width: 1500px) {
        width: 104.75%;
      }
      @media only screen and (min-width: 1510px) {
        width: 105%;
      }
      @media only screen and (min-width: 1520px) {
        width: 106%;
      }
      @media only screen and (min-width: 1535px) {
        width: calc(107% + 5vw);
      }
      @media only screen and (min-width: 1800px) {
        width: calc(115% + 5vw);
      }
      @media only screen and (min-width: 1920px) {
        width: 980px;
      }
    }

    @include atMedium {
      position: absolute;
    }
  }
}
