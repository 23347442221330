.origin-story {
  text-align: center;
  .section__title {
    color: hsl(0, 0%, 31%);
  }

  &__grid {
    @include atMedium {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: center;
      align-items: center;
    }
  }

  &__item {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include atMedium {
      flex: 0 0 45%;
      align-self: flex-start;
    }

    @include atLarge {
      flex: 0 0 32%;
      margin-bottom: 3rem;
    }
  }

  &__image {
    border-radius: 24px;
    min-height: 100%;
    overflow: hidden;

    @include atMedium {
      height: 235px;
    }

    @include atLarge {
      height: auto;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      @include atMedium {
        object-fit: cover;
      }
      @include atLarge {
        object-fit: fill;
      }
    }
  }

  &__description {
    padding: 0.5rem;
    line-height: 20px;
    color: #333;
    max-width: 90%;
    margin: auto;
    @include atMedium {
      padding: 0.5rem 1rem;
      max-width: 100%;
    }
  }

  &__intro {
    line-height: 1.5;
    font-size: 1.15rem;
    font-weight: 300;
    text-align: justify;
    @include atLarge {
      line-height: 1.7;
      font-size: 1.25rem;
    }
  }
}

.section.origin-story {
  padding-top: 1rem;
}
