.mobile-apps {
  & > .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    @include atX-Large {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__left,
  &__right {
    background-color: #2b2b2b;
    border-radius: 1.5rem;

    color: #fff;

    display: flex;
    gap: 10px;
    padding: 1rem;
    max-width: 590px;
    max-height: 380px;

    @media only screen and (min-width: 438px) {
      margin-bottom: 3.5rem;
    }

    @include atX-Large {
      flex: 1;
    }
  }

  &__right {
    flex-direction: row-reverse;
    background-color: #72d66f;
  }

  &__phone {
    @include atSmall {
    }

    img,
    picture {
      height: auto;
      width: 100%;
      @media only screen and (min-width: 465px) {
        height: 110%;
        width: auto;
        max-width: 200px;
      }

      @include atMedium {
        width: 200px;
        height: auto;
      }
    }
  }

  &__left {
    .mobile-apps__phone {
      @media only screen and (min-width: 465px) {
        text-align: right;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    @include atLarge {
      padding-left: 1rem;
    }

    p {
      line-height: 1.4;
      font-size: 18px;
      margin-bottom: 2rem;
      font-weight: 600;

      @include atMedium {
        font-size: 24px;
        line-height: 40px;
      }

      @include atLarge {
        font-size: 36px;
      }
    }

    .badges {
      display: flex;
      flex-direction: column;
      gap: 15px;

      @include atMedium {
        flex-direction: row;
      }

      .badge {
        background-color: #fff;
        padding: 0.5rem 0.8rem;

        @include atMedium {
          width: 110px;
        }
      }
    }
  }

  &__ndru {
    display: flex;
    align-items: center;
    max-height: 40px;
    margin-left: -5px;
  }
}
