.adatkezelesi-tajekoztato {
  * {
    margin: 0;
    padding: 0;
    text-indent: 0;
  }
  .s1 {
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
  }
  .p,
  p {
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
    margin: 0pt;
  }
  .a {
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
  }
  h1 {
    color: black;
    font-family: Georgia, serif;
    font-style: italic;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
  }
  .s2 {
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
  }
  .s3 {
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
  }
  .s4 {
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
  }
  .s5 {
    color: black;
    font-family: 'Times New Roman', serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
  }
  .s6 {
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
  }
  .s7 {
    color: black;
    font-family: Georgia, serif;
    font-style: italic;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
  }
  .s9 {
    color: #00f;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 10pt;
  }
  li {
    display: block;
  }
  #l1 {
    padding-left: 0pt;
    counter-reset: c1 1;
  }
  #l1 > li > *:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal) '. ';
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
  }
  #l1 > li:first-child > *:first-child:before {
    counter-increment: c1 0;
  }
  #l2 {
    padding-left: 0pt;
    counter-reset: d1 1;
  }
  #l2 > li > *:first-child:before {
    counter-increment: d1;
    content: counter(d1, lower-latin) ') ';
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
  }
  #l2 > li:first-child > *:first-child:before {
    counter-increment: d1 0;
  }
  #l3 {
    padding-left: 0pt;
    counter-reset: c2 1;
  }
  #l3 > li > *:first-child:before {
    counter-increment: c2;
    content: counter(c1, decimal) '.' counter(c2, decimal)
      ' ';
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
  }
  #l3 > li:first-child > *:first-child:before {
    counter-increment: c2 0;
  }
  #l4 {
    padding-left: 0pt;
  }
  #l4 > li > *:first-child:before {
    content: '● ';
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
  }
  #l5 {
    padding-left: 0pt;
    counter-reset: e2 1;
  }
  #l5 > li > *:first-child:before {
    counter-increment: e2;
    content: counter(e2, lower-latin) ') ';
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
  }
  #l5 > li:first-child > *:first-child:before {
    counter-increment: e2 0;
  }
  #l6 {
    padding-left: 0pt;
    counter-reset: e2 1;
  }
  #l6 > li > *:first-child:before {
    counter-increment: e2;
    content: counter(e2, lower-latin) ') ';
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
  }
  #l6 > li:first-child > *:first-child:before {
    counter-increment: e2 0;
  }
  #l7 {
    padding-left: 0pt;
  }
  #l7 > li > *:first-child:before {
    content: '- ';
    color: black;
    font-family: Georgia, serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
  }
  table,
  tbody {
    vertical-align: top;
    overflow: visible;
  }
}
