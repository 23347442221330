.hero1 {
  &__heading-d-none {
    display: none;
  }

  &__title {
    font-weight: 700;

    .bg-green {
      background-color: $mainGreen;
    }

    @media only screen and (max-width: 375px) {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    line-height: 2.5rem;
    // font-size: 2rem;
    font-size: 1.8rem;
    margin: 0 auto;
    margin-bottom: 2.5rem;

    text-align: center;
    max-width: 30rem;

    @include atLarge {
      line-height: 3rem;
      font-size: 2.5rem;
      margin-bottom: 3.5rem;
    }
  }

  &__badges {
    text-align: center;
    margin-bottom: 2.5625rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include atLarge {
      margin-bottom: 3.5rem;
    }

    a:first-of-type {
      margin-right: 0.5rem;

      @include atLarge {
        margin-right: 1rem;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;

    .phone {
      margin: auto;
      position: relative;

      & > img,
      & > svg {
        display: inline-block;
        width: 11.763125rem;
        height: 24.39625rem;

        @include atLarge {
          width: 17.25rem;
          height: 35.875rem;
        }
      }
    }

    .circle-1 {
      position: absolute;
      right: -2.875rem;
      top: 4.375rem;
      z-index: -1;
      @include atLarge {
        right: -4.875rem;
      }

      svg {
        width: 10.875rem;
        height: 11rem;

        @include atLarge {
          width: 16.3125rem;
          height: 16.5rem;
        }
      }
    }

    .circle-2 {
      position: absolute;
      bottom: 4.625rem;
      left: -2.1875rem;
      z-index: -1;
      @include atLarge {
        bottom: 5.875rem;
        left: -4rem;
      }

      svg {
        width: 5.6875rem;
        height: 5.75rem;

        @include atLarge {
          width: 9.3125rem;
          height: 9.375rem;
        }
      }
    }
  }
}
