.jumbotron {
  height: 470px;
  min-width: 344px;
  position: relative;

  @include atMedium {
    border-radius: 24px;
    height: 395px;
    overflow: hidden;
  }

  @include atLarge {
    overflow: visible;
    overflow-x: hidden;
    overflow-y: visible;
  }

 

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;

    @include atLarge {
      border-radius: 24px;
    }
  }

  &__title {
    color: #fff;
    font-size: 1.5rem;
    line-height: 2rem;
    position: absolute;
    bottom: 178px;
    left: 2rem;
    font-weight: 700;
    z-index: 1;

    @include atMedium {
      bottom: 226px;
    }

    @include atLarge {
      font-size: 2rem;
      line-height: 2.5rem;
      bottom: 226px;
      left: 3rem;
      bottom: 212px;
    }

    @include atX-Large {
      font-size: 2.25rem;
      left: 4rem;
    }
  }

  &__paragraph {
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.25rem;
    position: absolute;
    bottom: 102px;
    left: 2rem;

    @include atMedium {
      bottom: 170px;
    }

    @include atLarge {
      font-size: 1rem;
      line-height: 1.5rem;
      bottom: 150px;
      left: 3rem;
    }
    
    @include atX-Large {
      left: 4rem;
    }
  }

  &__btn {
    background-color: $mainGreen;
    display: inline-block;
    
    height: 48px;
    text-align: center;
    line-height: 48px;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 700;

    position: absolute;
    bottom: 40px;
    left: 2rem;
    right: 2rem;

    transition: filter 0.3s ease-in-out;

    &:hover,
    &:active {
      background-color: darken($mainGreen, 4%);
    }

    @include atMedium {
      width: 200px;
      bottom: 105px;
    }

    @include atLarge {
      left: 3rem;
      bottom: 80px;
    }

    @include atX-Large {
      left: 4rem;
    }
  }

  &__comp {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -75px;
  }

  &__comp2 {
    position: absolute;
    right: -172px;
    top: 30px;
    width: 450px;

    @include atLarge {
      height: 409px;
      width: 545px;
      top: -37px;
      right: -127px;
      width: auto;
    }
  }

  &__ellipse {
    position: absolute;
    top: -37px;
    right: 213px;
    z-index: -1;
    width: 220px;
    height: 220px;
  }
}