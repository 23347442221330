.ndru-is-growing {
  margin-top: 2rem;

  @include atMedium {
    margin-top: 4rem;
  }

  @include atLarge {
    margin-top: 10rem;
  }

  @include atX-Large {
    margin-top: 18rem;
  }

  &__title {
    font-size: 1.625rem;
    line-height: 48px;
    text-align: center;
    color: $textDarkgrey;
    margin: 0;
    margin-bottom: 1.5rem;

    @include atMedium {
      margin-bottom: 2.5rem;
    }

    @include atLarge {
      font-size: 2.25rem;
      margin-bottom: 4.5rem;
    }
  }

  &__subtitle {
    margin: 0;
    text-align: justify;
    line-height: 30px;
    margin-bottom: 1rem;

    max-width: 586px;

    @include atLarge {
      margin-left: auto;
      font-size: 1.25rem;
      line-height: 40px;
    }
  }

  &__paragraph {
    margin: 0;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 1rem;

    max-width: 586px;

    @include atLarge {
      margin-left: auto;
      line-height: 40px;
    }
  }

  &__list-title {
    color: $mainGreen;
    font-weight: 400;
    margin: 0;
    margin-bottom: 1rem;
    line-height: 40px;

    @include atMedium {
      margin-left: 2rem;
      line-height: 1.4;
      margin-bottom: 1.5rem;
      font-size: 1.2rem;
    }

    @include atLarge {
      font-size: 1.5rem;

      margin-left: 5rem;
      margin-bottom: 2.5rem;
    }
  }

  &__list {
    color: $mainGreen;
    list-style: none;
    padding-left: 1rem;

    @include atMedium {
      padding-left: 3rem;
    }

    @include atLarge {
      padding-left: 8rem;
    }

    @include atX-Large {
      padding-left: 10rem;
    }

    li {
      margin-bottom: 1rem;
      display: flex;

      @include atLarge {
        margin-bottom: 1.5rem;
      }

      &::before {
        content: '';
        display: inline-block;
        height: 24px;
        width: 24px;
        background-image: url('../../assets/images/careers/ndru-is-growing/ic_checkmark.svg');
        background-repeat: no-repeat;
        background-position: center center;
      }

      span {
        display: inline-block;
        vertical-align: top;
        padding-top: 5px;
        margin-left: 0.5rem;
        font-weight: 300;

        @include atLarge {
          margin-left: 1.5rem;
          font-size: 1.1rem;
          padding-top: 2px;
        }
      }
    }
  }
}
