.contact-us {
  margin-top: 8rem;
  margin-bottom: 6rem;

  .message-has-been-sent {
    color: #66bb6a;
    font-size: 1.5rem;
    text-align: center;
    margin: 2rem;

    &.error {
      color: red;
    }
  }

  @include atLarge {
    margin-top: 12rem;
    margin-bottom: 8rem;
  }

  h2 {
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    margin: 3rem auto;
  }

  .terms {
    margin-bottom: 3rem;
    height: 1.875rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include atLarge {
      margin-bottom: 2rem;
      justify-content: flex-end;
    }

    p {
      margin-left: 2rem;
      margin-top: 28px;
      line-height: 1.4;
      font-weight: bold;
      a {
        color: $mainGreen;
      }
    }

    .round {
      position: relative;
    }

    .round label {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%;
      cursor: pointer;
      height: 28px;
      left: 0;
      position: absolute;
      top: 0;
      width: 28px;
    }

    &.error {
      .round label {
        border-color: red;
      }
    }

    .round label:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: '';
      height: 6px;
      left: 7px;
      opacity: 0;
      position: absolute;
      top: 8px;
      transform: rotate(-45deg);
      width: 12px;
    }

    .round input[type='checkbox'] {
      visibility: hidden;
    }

    .round input[type='checkbox']:checked + label {
      background-color: #66bb6a;
      border-color: #66bb6a;
    }

    .round input[type='checkbox']:checked + label:after {
      opacity: 1;
    }
  }

  input:not([type='checkbox']),
  textarea {
    display: inline-block;
    background-color: #fafafa;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    height: 60px;
    padding: 0.5rem;
    width: 100%;
    margin-bottom: 2rem;
    z-index: 10;

    &.error {
      border-color: red;
    }
  }

  .form-group {
    position: relative;
    margin-bottom: 0.5rem;

    &.error {
      border-color: red;
    }

    textarea {
      padding-top: 1.5rem;
    }

    input {
      transition: all 0.25s ease-in-out;
    }

    input:not(:placeholder-shown),
    input:focus {
      padding-top: 1.25rem;
    }

    input:not(:placeholder-shown) ~ label,
    textarea:not(:placeholder-shown) ~ label {
      font-size: 0.7rem;
    }

    input:focus ~ label,
    textarea:focus ~ label {
      font-size: 0.7rem;
    }

    label {
      position: absolute;
      font-size: 0.8rem;
      color: #797575;
      top: 0.5rem;
      left: 1.5rem;
      transition: all 0.25s ease-in-out;
    }
  }

  ::placeholder {
    font-size: 0.8rem;
  }

  ::-webkit-input-placeholder {
    font-size: 0.8rem;
  }

  ::-moz-placeholder {
    font-size: 0.8rem;
  }

  :-ms-input-placeholder {
    font-size: 0.8rem;
  }

  textarea {
    height: 335px;
  }

  select {
    background-color: #fff;
    width: 100%;
  }

  .select-topic-type {
    position: relative;
    .errorMessage {
      position: absolute;
      color: red;
      font-size: 0.8rem;
      bottom: 17px;
      right: 40px;
      display: none;
      &.show {
        display: block;
        @include atMedium {
          display: none;
        }
      }
    }

    &.error {
      border-color: red;
    }

    input[type='checkbox'] + label {
      display: block;
      cursor: pointer;
    }

    input[type='checkbox'] {
      display: none;
    }

    input[type='checkbox'] + label:before {
      content: '\2714';
      border: 1px solid #e7e7e7;
      border-radius: 3px;
      width: 19px;
      height: 19px;
      display: inline-block;
      margin-right: 0.5rem;
      padding-left: 2px;
      color: transparent;
      vertical-align: bottom;
    }

    input[type='checkbox']:checked + label:before {
      background-color: $mainGreen;
      border-color: $mainGreen;
      color: #fff;
    }
  }

  .select-topic-type {
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 4px 4px rgba(31, 33, 37, 0.2);
    margin-bottom: 2.75rem;
    margin-top: 0.5rem;
    height: 51px;
    transition: all 0.25s ease-in-out;
    background-color: #fafafa;

    @include atMedium {
      position: absolute;
      z-index: 10;
      max-width: 100%;
      margin-bottom: 0;
      margin-top: 0;
      height: 58px;
      width: 270px;
    }

    @include atLarge {
      width: 290px;
    }

    @include atX-Large {
      width: 350px;
    }

    .topic-type {
      cursor: pointer;
      font-size: 0.9rem;
      color: #797575;
      padding: 1rem;
      margin: 0;
      padding-bottom: 1rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @include atMedium {
        height: 58px;
        max-width: 100%;
      }

      img {
        display: block;
        transition: all 0.25s ease-in-out;

        width: 10px;
      }
    }
  }

  .select-topic-type.show {
    height: 430px;

    @include atMedium {
      height: 450px;

      .errorMessage {
        display: block;
        right: 5px;
        bottom: 5px;
      }
    }

    .topic-type {
      border-bottom: 1px solid rgba(31, 33, 37, 0.2);
    }

    img {
      transform: rotate(180deg);
    }
  }

  .checkbox-container {
    padding: 1rem;
  }
  .checkbox-container:nth-of-type(5) {
    label::before {
      float: left;
      display: inline-block;
    }
    span {
      line-height: 1.2;
      float: right;

      width: 90.5%;

      @media (min-width: 420px) {
        width: 91.5%;
      }

      @media (min-width: 445px) {
        width: 92.5%;
      }

      @media (min-width: 500px) {
        width: 93.5%;
      }

      @include atMedium {
        width: 88.5%;
      }

      @include atX-Large {
        width: 90.5%;
      }
    }

    @include clearfix();
  }

  .liveValidateMessage {
    top: -24px;
    left: 5px;
    position: absolute;
    z-index: -1;
    padding-top: 6px;
    padding-bottom: 16px;
    padding-left: 0.8rem;
    padding-right: 0;
    font-size: 0.8rem;
    color: red;

    @include atMedium {
      top: auto;
      bottom: 76px;
    }
  }

  textarea + .liveValidateMessage {
    @include atMedium {
      top: auto;
      bottom: 357px;
    }
  }

  .liveValidateMessage--visible {
    opacity: 1;
    transform: translateY(0);
  }

  .liveValidateMessage-enter {
    opacity: 0;
    transform: translateY(100%);
  }

  .liveValidateMessage-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
  }

  .liveValidateMessage-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .liveValidateMessage-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: 0.33s opacity ease-in-out, 0.33s transform ease-in-out;
  }
}
