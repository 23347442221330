.hero-our-mission {
  display: flex;
  flex-direction: column;

  @include atMedium {
    flex-direction: row;
  }

  &__texts {
    margin-bottom: 3.125rem;
    max-width: 39.5rem;

    @include atMedium {
      width: 60%;
    }

    @include atX-Large {
      margin-top: 6rem;
    }
  }

  &__title {
    color: $mainGreen;
    font-size: 1.625rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;

    @include atMedium {
      text-align: left;
    }

    @include atLarge {
      font-size: 2.25rem;
    }
  }

  &__subtitle {
    font-size: 1.125rem;
    line-height: 1.4;
    text-align: center;
    margin: 0;
    margin-bottom: 1rem;

    @include atMedium {
      text-align: left;
    }

    @include atLarge {
      font-size: 1.5rem;
    }
  }

  &__buttons {
    @include atMedium {
      margin-top: 2rem;
    }
  }

  &__image {
    height: 250px;
    overflow: hidden;

    position: relative;
    background-image: url(/assets/images/our-mission-page/hero/hero.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 32%;

    @include atMedium {
      width: 40%;
      height: 350px;
      position: absolute;
      right: 0;
      top: 72px;
    }

    @include atLarge {
      height: 450px;
    }

    @include atX-Large {
      height: 650px;
    }

    &--careers {
      background-image: url(/assets/images/careers/hero/hero.jpg);
    }
  }
}
