.blog-card {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 90%;
  width: 700px;
  height: 500px;
  margin: 0 auto;
  border-radius: 27px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba($color: #000000, $alpha: 0.5);
  margin-bottom: 5rem;
  transition: 0.25s;

  &:hover,
  &:focus {
    box-shadow: 2px 4px 20px rgba($color: #000000, $alpha: 0.4);
    transform: translateY(-0.5em);
  }

  @include atMedium {
    height: 700px;
  }

  @include atLarge {
    margin-bottom: 10rem;
  }

  &__img-container {
    height: 50%;
    overflow: hidden;

    & > img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      transition: transform 3s ease-in;
    }
  }

  &:hover .blog-card__img,
  &:focus .blog-card__img {
    transform: scale(1.1);
    overflow: hidden;
  }

  &__body {
    height: 50%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include atMedium {
      padding: 1.5rem;
    }
  }

  &__header {
    color: $mainGreen;
    font-weight: 300;
    font-size: 14px;

    @include atMedium {
      font-size: 16px;
    }
  }

  &__main-content {
    flex: 1;
  }

  &__title {
    font-size: 16px;
    margin: 0;
    margin-top: 1.5rem;
    line-height: 1.4;

    @include atMedium {
      margin-top: 2.5rem;
      font-size: 24px;
    }
  }

  &__text {
    font-size: 14px;
    margin: 0;
    margin-top: 0.5rem;
    line-height: 1.4;

    @include atMedium {
      margin-top: 1rem;
      font-size: 18px;
      line-height: 1.6;
    }
  }

  &__date {
    margin-top: auto;
    color: $textMuted;

    @include atMedium {
      font-size: 14px;
    }
  }
}
