.benefits {
  margin-top: 3rem;
  padding-bottom: 0.5rem;

  @include atSmall {
    margin-top: 4.5rem;
  }

  @include atLarge {
    margin-top: 6.5rem;
  }

  @include atX-Large {
    margin-top: 8.5rem;
  }

  &__title {
    font-size: 1.625rem;
    line-height: 48px;
    text-align: center;
    color: $textDarkgrey;
    margin: 0;
    margin-bottom: 2rem;

    @include atMedium {
      margin-bottom: 2.5rem;
    }

    @include atLarge {
      font-size: 2.25rem;
      margin-bottom: 4.5rem;
    }
  }

  &__grid {
    display: grid;

    @include atSmall {
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      justify-items: center;
    }

    @include atLarge {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 0;
      justify-items: center;
    }
  }

  &__item {
    width: 90%;
    height: 341px;
    margin: auto;
    margin-bottom: 2rem;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    font-size: 25px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    overflow: hidden;

    @include atSmall {
      width: 239px;

      margin: 0;
      justify-self: end;

      &:nth-child(even) {
        justify-self: start;
      }
    }

    @include atLarge {
      width: 200px;
      justify-self: auto;
      margin-bottom: 2.5rem;

      &:nth-child(even) {
        justify-self: auto;
      }
    }

    @media (min-width: 1100px) {
      width: 220px;
    }

    @media (min-width: 1200px) {
      width: 240px;
    }

    @media (min-width: 1200px) {
      width: 255px;
      margin-bottom: 3rem;
    }
  }
}
