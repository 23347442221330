.not-found {
  min-height: 60vh;
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;

  h1 {
    font-size: 5rem;
    color: $mainGreen;
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 0rem;
  }
  h3 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
}
