.hubspot_form {
  background-color: #1F2125;
  color: #fff;

  .form-hidden{
    display: none;
  }

  .form_container{
    margin: auto;
    width: 80%;
    padding-bottom: 4rem;
  }

  iframe {
    padding-top: 0.5rem;
  }

  .section_title{
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 3rem;

    h2 {
      line-height: 2rem;
      font-size: 1.5rem;
  
      @include atMedium {
        font-size: 2.25rem;
        line-height: 2.5rem;
      }
    }
  }
  
}