.section {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;

  @include atLarge {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  @include atLarge {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &--hero1 {
    // padding-top: 2.5rem;
    padding-top: 40px;
    @include atLarge {
      padding-top: 4.875rem;
    }
  }

  &--hero2 {
    padding-top: 10px;

    @include atMedium {
      padding-top: 2.8rem;
    }

    @media only screen and (min-width: 870px) {
      margin-bottom: 1rem;
    }

    @media only screen and (min-width: 1150px) {
      margin-bottom: 3rem;
    }

    @include atX-Large {
      margin-bottom: 6rem;
    }
    @media only screen and (min-width: 1230px) {
      margin-bottom: 7rem;
    }

    @media only screen and (min-width: 1300px) {
      margin-bottom: 7rem;
    }
    @media only screen and (min-width: 1400px) {
      margin-bottom: 7rem;
    }
  }

  &--hero3 {
    padding-top: 10px;

    @include atMedium {
      margin-bottom: 4rem;
      padding-top: 2.8rem;
    }
    @media only screen and (min-width: 830px) {
      margin-bottom: 5rem;
    }

    @include atX-Large {
      margin-bottom: 6rem;
    }

    @media only screen and (min-width: 1300px) {
      margin-bottom: 8rem;
    }

    @media only screen and (min-width: 1570px) {
      margin-bottom: 10rem;
    }

    @media only screen and (min-width: 1605px) {
      margin-bottom: 11rem;
    }
    @media only screen and (min-width: 1630px) {
      margin-bottom: 12.5rem;
    }

    // png
    @media only screen and (min-width: 1669px) {
      margin-bottom: 18rem;
    }
  }

  &--extra-padding-bottom {
    padding-bottom: 6rem !important;
  }

  &--extra-padding-bottom-sm {
    padding-bottom: 4rem !important;
  }

  &--border-top-until-md {
    @include untilMedium {
      border-top: 1px solid #e7e7e7;
    }
  }

  &__title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
    margin-bottom: 2rem;

    &--location {
      margin-bottom: 1.6rem;
    }

    @include atLarge {
      font-size: 2.25rem;
      line-height: 2.5rem;
      margin-bottom: 3.4375rem;

      &--location {
        margin-bottom: 3.2375rem;
      }
    }

    &--slider {
      margin-bottom: 0;
      @include atMedium {
        margin-bottom: 15px;
      }

      @media only screen and (max-width: 600px) {
        margin-left: 1rem;
      }
    }

    &--align-left-from-md {
      @include atMedium {
        text-align: left;
      }
    }

    &--text-align-left {
      text-align: left;
    }

    &--margin-bottom-sm {
      margin-bottom: 0.5rem;

      @include atMedium {
        margin-bottom: 1rem;
      }

      @include atLarge {
        margin-bottom: 1.8rem;
      }
    }

    &--more-than {
      @include atMedium {
        margin-top: 0.75rem;
      }
      @include atLarge {
        font-size: 3rem;
        line-height: 1.1;
      }
    }
  }

  &__image {
    img {
      display: block;
      width: 95%;
      margin: 0 auto;
      height: auto;
    }

    &--left {
      img {
        @include atLarge {
          width: 90%;
          margin: 0 auto 0 0;
        }
      }
    }
    &--right {
      img {
        @include atLarge {
          width: 90%;
          margin: 0 0 0 auto;
        }
      }
    }
  }

  &__text-content {
    &--left {
      @include atLarge {
        padding-right: 3rem;
      }
    }

    &--right {
      @include atLarge {
        padding-left: 3rem;
      }
    }
  }

  &__paragraph {
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: $textMuted;
    max-width: 28.75rem;

    @include atMedium {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  &--no-padding-bottom {
    padding-bottom: 0;
  }

  &__col {
    &--border-bottom-until-md {
      border-bottom: 1px solid #e7e7e7;
      @include atMedium {
        border-bottom: none;
      }
    }
  }

  &--border-bottom-from-md {
    @include atMedium {
      border-bottom: 1px solid #e7e7e7;
    }
  }

  &--slider {
    border-top: 1px solid #e7e7e7;
    @include atMedium {
      border-top: none;
    }
  }
}
