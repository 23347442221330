.open-positions {
  margin-top: 2.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 2.5rem;

  @include atSmall {
    margin-top: 4.5rem;
  }

  @include atMedium {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem;
  }

  @include atLarge {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem;
  }

  @include atX-Large {
    margin-top: 8.5rem;
  }

  &__title {
    font-size: 1.625rem;
    line-height: 48px;
    text-align: center;
    color: $textDarkgrey;
    margin: 0;
    margin-bottom: 1.5rem;

    @include atMedium {
      margin-bottom: 2.5rem;
    }

    @include atLarge {
      font-size: 2.25rem;
      margin-bottom: 4.5rem;
    }
  }

  &__subtitle {
    color: #2f80ed;
    font-weight: 300;
    margin: 0;
    text-align: center;
    margin-bottom: 1.5rem;

    &::before {
      content: '';
      display: inline-block;
      height: 25px;
      width: 25px;
      background-image: url('/assets/images/careers/open-positions/ic_settings.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    span {
      vertical-align: top;
      display: inline-block;
      margin-left: 0.5rem;
      font-size: 1.5rem;
      font-weight: 300;
    }

    &:nth-of-type(2) {
      margin-top: 5rem;
      &::before {
        background-image: url('/assets/images/careers/open-positions/ic_briefcase.svg');
      }
    }
  }

  &__item {
    color: #fff;
    background-color: #2f80ed;
    border: 1px solid #2f80ed;
    border-radius: 8px;
    overflow: hidden;
    width: 316px;
    height: 288px;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    @include atSmall {
      margin: 0;
    }

    .header {
      background-color: inherit;
      padding: 0.5rem;
      height: 35px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 300;

      .level {
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          content: '';
          display: inline-block;
          height: 20px;
          width: 20px;
          background-image: url('/assets/images/careers/open-positions/ic_preferences.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          margin-right: 0.5rem;
        }

        span {
          vertical-align: top;
          display: inline-block;
          margin-top: 1px;
        }
      }
    }

    .body {
      flex: 1;
      background-color: #fff;
      color: $mainText;
      font-weight: 300;
      border-radius: 8px;
      position: relative;

      .type-and-location {
        padding: 0.5rem;
        display: flex;
        border-bottom: 1px solid;
        align-items: center;

        .type {
          flex: 1;
          border-right: 1px solid;
          padding: 0.5rem;

          &::before {
            content: '';
            display: inline-block;
            height: 20px;
            width: 20px;
            background-image: url('/assets/images/careers/open-positions/ic_clock.svg');
            background-repeat: no-repeat;
            background-position: center 1px;
            background-size: contain;
            margin-right: 0.5rem;
            vertical-align: middle;
          }

          span {
            vertical-align: top;
            display: inline-block;
            margin-top: 3px;
          }
        }

        .location {
          flex: 1;
          padding: 0.5rem;
          padding-left: 1rem;

          &::before {
            content: '';
            display: inline-block;
            height: 20px;
            width: 20px;
            background-image: url('/assets/images/careers/open-positions/ic_pin.svg');
            background-repeat: no-repeat;
            background-position: center 1px;
            background-size: contain;
            margin-right: 0.5rem;
            vertical-align: middle;
          }

          span {
            vertical-align: top;
            display: inline-block;
            margin-top: 3px;
          }
        }
      }

      .requirements {
        padding-top: 0.5rem;
        padding-left: 2.2rem;

        li {
          margin-bottom: 0.6rem;
          line-height: 1.4;
        }
      }

      .link {
        position: absolute;
        display: inline-block;
        right: 0.5rem;
        bottom: 0.5rem;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        border: 1px solid #2f80ed;
        padding: 2px;
        transition: 0.25s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
        }

        &:hover {
          background-color: #2f80ed;

          svg {
            path {
              stroke: #fff;
            }
          }
        }
      }
    }
  }

  &__grid {
    @include atSmall {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
    }

    @include atMedium {
      gap: 2rem;
    }
  }
}
