.hero-pricing-page {
  display: flex;
  flex-direction: column;

  @include atMedium {
    flex-direction: row;
  }

  &__texts {
    margin-bottom: 3.125rem;

    @include atMedium {
      width: 60%;
    }

    @include atLarge {
      width: 50%;
      margin-top: 3rem;
    }

    @include atX-Large {
      margin-top: 5rem;
    }

    @media only screen and (min-width: 1655px) {
      margin-top: 7rem;
    }
  }

  &__image {
    @include atMedium {
      width: 40%;
    }

    @include atLarge {
      width: 50%;
    }

    picture {
      display: block;
      width: 100%;

      @include atMedium {
        margin-left: 2rem;
        margin-top: 2rem;
      }

      @include atLarge {
        margin-left: 2rem;
        margin-top: 1rem;
      }

      img {
        width: 100%;

        @include atMedium {
          width: calc(100% + 10vw);
        }

        @media only screen and (min-width: 945px) {
          width: calc(100% + 12vw);
        }

        @media only screen and (min-width: 1550px) {
          width: calc(100% + 14vw);
        }

        @media only screen and (min-width: 1655px) {
          width: calc(100% + 16vw);
        }

        @media only screen and (min-width: 1685px) {
          width: calc(100% + 18vw);
        }

        @media only screen and (min-width: 1920px) {
          width: 900px;
        }
      }
    }
  }

  &__title {
    color: $mainGreen;
    font-size: 1.625rem;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;

    @include atMedium {
      text-align: left;
    }

    @include atLarge {
      font-size: 2.25rem;
    }
  }

  &__h1 {
    font-size: 2rem;
    text-align: center;
    line-height: 2.5rem;
    margin: 0;
    margin-bottom: 1rem;

    @include atMedium {
      text-align: left;
    }

    @include atLarge {
      font-size: 2.25rem;
      line-height: 3rem;
    }
  }

  &__subtitle {
    font-size: 1.125rem;
    line-height: 1.4;
    text-align: center;
    margin: 0;
    margin-bottom: 1rem;

    @include atMedium {
      text-align: left;
    }

    @include atLarge {
      font-size: 1.5rem;
    }
  }

  &__buttons {
    @include atMedium {
      margin-top: 2rem;
    }
  }
}
