.cases {
  &__item {
    background-color: $mainGreen;
    padding: 1.5rem;
    border-radius: 24px;
    height: 100%;
  }

  &__icon {
    width: 30px;
    margin-bottom: 1rem;
  }

  &__title {
    font-weight: bold;
    font-size: 1.25rem;
    padding-bottom: 5px;
    border-bottom: 1px dotted #000;
    margin-bottom: 2rem;

    @media (min-width: 1126px) {
      font-size: 1.5rem;
    }
  }

  &__text {
    font-size: 1rem;
    line-height: 30px;
    margin-bottom: 2rem;
  }
}
