.desktop-nav{
  @include atMedium {
    padding: 1.5rem 0;
  }

  @include atLarge {
    padding: 1.7rem 0;
  }

  ul {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    display: inline;
    
  }

  a {
    &:hover {
      color: $mainText;
      opacity: 0.8;
    }
  }
}

.dropdown-menu {
  position: relative;
  display: inline-block;

  .dropdown-content {
    display: none;
    position: fixed;
    background-color: #f1f1f1;
    border-radius: 5px;
    padding: 0.5rem;

    a {
      padding: 12px 16px;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: $mainGreen;
        opacity: 0.8;
      }

      svg {
        width: 0.5rem;
        height: 0.9rem;
  
        @include atMedium {
          width: 0.375rem;
          height: 0.75rem;
        }
      }
      svg path {
        stroke: $textMuted;
  
        @include atMedium {
          stroke: $textMuted;
        }
      }
    }
  }
}
.dropdown-menu:hover .dropdown-content {
  display: block;
}


.primary-nav {
  @include atMedium {
    padding: 1.5rem 0;
  }

  @include atLarge {
    padding: 1.7rem 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin-bottom: 40px;

    @include atMedium {
      margin-bottom: 20px;
    }

    @include atLarge {
      margin-bottom: 22px;
    }

    &:last-of-type {
      margin-bottom: 0;
      margin-top: 90px;

      @include atMedium {
        margin-top: 55px;
      }

      @include atLarge {
        margin-top: 57px;
      }
    }
  }

  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    user-select: none;
    cursor: pointer;

    svg {
      width: 0.5rem;
      height: 0.9rem;

      @include atMedium {
        width: 0.375rem;
        height: 0.75rem;
      }
    }
    svg path {
      stroke: $textMuted;

      @include atMedium {
        stroke: $textMuted;
      }
    }

    &:hover {
      color: $mainText;
      opacity: 0.8;
    }

    @include atMedium {
      font-size: 0.9375rem;
      font-weight: 400;
    }

    @include atLarge {
      font-size: 1rem;
    }
  }

  &__link--last {
    font-weight: 600 !important;
    svg path {
      stroke: $mainText !important;
    }

    @include atMedium {
      svg path {
        stroke: $mainText !important;
      }

      &:hover {
        color: $mainText !important;

        svg path {
          stroke: $mainText !important;
        }
      }
    }
  }
}
