.timeline {
  @include untilMedium {
    @include atMedium {
      margin-top: 6rem;
    }

    ul {
      padding: 25px 0;
      margin: 0;
    }

    // Create line
    ul li {
      list-style: none;
      position: relative;
      width: 3px;
      margin: 0 auto;
      background-color: $mainGreen;

      &:not(:first-child) {
        padding-top: 50px;
      }
    }

    // Boxes
    ul li div {
      position: relative;
      width: 115px;
      height: 90px;
      bottom: 30px;
      text-align: center;
      font-size: 0.8rem;
      border-radius: 8px;
      background-color: #454545;
      color: #fff;
      border: 1px solid #454545;

      transition: all 0.8s ease-in-out;
      visibility: hidden;
      opacity: 0;

      line-height: 1.4;

      span {
        padding: 0.2rem;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25%;
      }

      p {
        margin: 0;
        padding: 0.2rem;
        border-radius: 8px;
        background-color: #fff;
        color: #454545;
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        overflow: hidden;
      }

      @include atSmall {
        width: 179px;
        height: 135px;
        bottom: 53px;

        span {
          padding: 0.5rem;

          font-size: 20px;
        }

        p {
          padding: 0.5rem;

          font-size: 16px;
        }
      }
    }
    /* Right Side */
    ul li:nth-child(odd) div {
      left: 50px;
      transform: translate(200px, 0);
    }
    /* Left Side */
    ul li:nth-child(even) div {
      left: -162px;
      transform: translate(-200px, 0);
      @include atSmall {
        left: -226px;
      }
    }

    // Show boxes
    ul li.show div {
      transform: none;
      visibility: visible;
      opacity: 1;
    }

    // Dots
    ul li::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 25px;
      height: 25px;
      background-color: #fff;
      border: 2px solid $mainGreen;
      border-radius: 50%;
      transition: background-color 0.8s ease-in-out;
    }
    // Show dots
    ul li.show:before {
      background: $mainGreen;
    }

    // Icons
    ul li::after {
      content: '';
      position: absolute;
      left: -50px;

      top: 0;

      width: 25px;
      height: 25px;
      background-image: url('/assets/images/our-mission-page/icons/ic_checkmark.png');
      background-repeat: no-repeat;
      background-position: center center;

      transition: all 0.8s ease-in-out 0.3s;
      visibility: hidden;
      opacity: 0;
    }
    ul li:not(:first-child)::after {
      top: 51px;
      @include atSmall {
        top: 26%;
      }
    }
    /* right side icons */
    ul li:nth-child(even)::after {
      left: 24px;
    }
    // Show icons
    ul li.show:after {
      transform: scale(0.9);
      visibility: visible;
      opacity: 1;
    }

    // icon 2.
    ul li:nth-child(2)::after {
      background-image: url('/assets/images/our-mission-page/icons/ic_send.png');
    }

    // icon 3.
    ul li:nth-child(3)::after {
      background-image: url('/assets/images/our-mission-page/icons/ic_service.png');
    }

    // icon 4.
    ul li:nth-child(4)::after {
      background-image: url('/assets/images/our-mission-page/icons/ic_preferences.png');
    }

    // icon 5.
    ul li:nth-child(5)::after {
      background-image: url('/assets/images/our-mission-page/icons/ic_settings.png');
    }

    // DottedLines base
    div:before {
      content: '';
      position: absolute;
      bottom: 50%;
      transform: translateY(-50%);
      border-top: 2px dotted $mainGreen;
      border-color: transparent;
      width: 50px;
      z-index: -1;
      transition: border-color 0.8s ease-in-out 0.3s;
    }

    ul li.show div:before {
      border-color: $mainGreen;
    }

    // Right Side DottedLines
    ul li:nth-child(odd) div:before {
      left: -37px;
    }
    // Left Side DottedLines
    ul li:nth-child(even) div:before {
      right: -37px;
    }
  }
}

.timeline--at-large {
  @include atMedium {
    display: block;
    margin-top: 4rem;
    margin-bottom: 10rem;

    @include atMedium {
      margin-bottom: 8rem;
    }

    @include atX-Large {
      margin-top: 9rem;
      margin-bottom: 14rem;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      width: 100%;
      padding-left: 58px;

      @include atLarge {
        padding-left: 71px;
      }

      @include atX-Large {
        padding-left: 89px;
      }
    }

    // Create line
    ul li {
      position: relative;
      height: 3px;
      background-color: $mainGreen;
      flex: 1;
    }

    // Icons
    ul li::after {
      content: '';
      position: absolute;

      width: 25px;
      height: 25px;
      background-image: url('/assets/images/our-mission-page/icons/ic_checkmark.png');
      background-repeat: no-repeat;
      background-position: center center;

      top: -55px;
      transform: translateX(-50%) scale(0.9);
    }

    // icon 2.
    ul li:nth-child(2)::after {
      left: 15px;

      background-image: url('/assets/images/our-mission-page/icons/ic_send.png');
    }

    // icon 3.
    ul li:nth-child(3)::after {
      left: 30px;

      background-image: url('/assets/images/our-mission-page/icons/ic_service.png');
    }

    // icon 4.
    ul li:nth-child(4)::after {
      left: 45px;

      background-image: url('/assets/images/our-mission-page/icons/ic_preferences.png');
    }

    // icon 5.
    ul li:nth-child(5)::after {
      left: 60px;

      background-image: url('/assets/images/our-mission-page/icons/ic_settings.png');
    }

    // Dots
    ul li::before {
      content: '';
      position: absolute;
      left: 0;
      transform: translateX(-50%);
      top: -10px;
      width: 25px;
      height: 25px;
      background-color: $mainGreen;
      border: 2px solid $mainGreen;
      border-radius: 50%;
      z-index: 1;
    }
    ul li:nth-child(2)::before {
      left: 15px;
    }
    ul li:nth-child(3)::before {
      left: 30px;
    }
    ul li:nth-child(4)::before {
      left: 45px;
    }
    ul li:nth-child(5)::before {
      left: 60px;
    }

    // Boxes
    ul li div {
      position: relative;
      width: 115px;
      height: 90px;
      bottom: -35px;
      text-align: center;
      font-size: 0.8rem;
      border-radius: 8px;
      background-color: #454545;
      color: #fff;
      border: 1px solid #454545;
      transform: translateX(-50%);
      line-height: 1.4;

      @include atLarge {
        width: 140px;
        height: 100px;
        font-size: 1rem;
        bottom: -42px;
      }

      @include atX-Large {
        bottom: -55px;
        width: 179px;
        height: 135px;
        font-size: 1.25rem;
      }

      span {
        padding: 0.2rem;
        display: block;

        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        margin: 0;
        padding: 0.2rem;
        border-radius: 8px;
        background-color: #fff;
        color: #454545;

        height: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;

        @include atLarge {
          font-size: 14px;
        }
      }
    }

    ul li:nth-child(2) div {
      left: 15px;
    }
    ul li:nth-child(3) div {
      left: 30px;
    }
    ul li:nth-child(4) div {
      left: 45px;
    }
    ul li:nth-child(5) div {
      left: 60px;
    }

    // DottedLines base
    div:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -32px;
      height: 30px;
      border-left: 2px dotted $mainGreen;
      z-index: -1;

      @include atLarge {
        height: 40px;
        top: -42px;
      }

      @include atX-Large {
        height: 45px;
        top: -47px;
      }
    }
  }
}

.section.timeline {
  padding-top: 0;
  @include atSmall {
    padding-top: 2.8rem;
  }
}
