.hero3 {
  &__row {
    display: flex;
    flex-direction: column;

    @include atMedium {
      flex-direction: row;
      margin-bottom: 5vw;
    }
  }

  &__column1 {
    margin-bottom: 2.5rem;

    @include atMedium {
      margin-bottom: 0;
      width: 47%;
    }

    @include atLarge {
      margin-bottom: 0;
      width: 500px;
    }

    @include atX-Large {
      margin-top: 3.5rem;
    }

    @media only screen and (min-width: 1669px) {
      margin-top: 4.5rem;
    }
  }

  &__column2 {
    @include atMedium {
    }

    @include atLarge {
    }

    @include atX-Large {
    }
  }

  &__image {
    img,
    svg {
      display: block;
      width: 100%;
      margin: auto;
      @media only screen and (min-width: 1320px) {
        width: calc(100% + 2vw);
      }
      @media only screen and (min-width: 1370px) {
        width: calc(100% + 3vw);
      }
      @media only screen and (min-width: 1400px) {
        width: calc(100% + 4vw);
      }
      @media only screen and (min-width: 1430px) {
        width: calc(100% + 5vw);
      }
      @media only screen and (min-width: 1460px) {
        width: calc(100% + 6vw);
      }
      @media only screen and (min-width: 1495px) {
        width: calc(100% + 7vw);
      }
      @media only screen and (min-width: 1530px) {
        width: calc(100% + 8vw);
      }
      @media only screen and (min-width: 1570px) {
        width: calc(100% + 9vw);
      }
      @media only screen and (min-width: 1605px) {
        width: calc(101% + 10vw);
      }
      @media only screen and (min-width: 1630px) {
        width: calc(102% + 13.5vw);
      }
      @media only screen and (min-width: 1920px) {
        width: 980px;
      }
    }

    &--at-lg-png {
      img,
      svg {
        @media only screen and (min-width: 1320px) {
          width: calc(97% + 2vw);
        }
        @media only screen and (min-width: 1370px) {
          width: calc(95% + 3vw);
        }
        @media only screen and (min-width: 1400px) {
          width: calc(93% + 4vw);
        }
        @media only screen and (min-width: 1430px) {
          width: calc(91% + 5vw);
        }
        @media only screen and (min-width: 1460px) {
          width: calc(90% + 6vw);
        }
        @media only screen and (min-width: 1495px) {
          width: calc(87% + 7vw);
        }
        @media only screen and (min-width: 1530px) {
          width: calc(86% + 8vw);
        }
        @media only screen and (min-width: 1570px) {
          width: calc(84% + 9vw);
        }
        @media only screen and (min-width: 1605px) {
          width: calc(81% + 10vw);
        }
        @media only screen and (min-width: 1630px) {
          width: calc(76% + 13.5vw);
        }
        @media only screen and (min-width: 1920px) {
          width: 1024px;
        }
      }
    }

    @include atMedium {
      position: absolute;
      top: 50px;
    }
  }
}
