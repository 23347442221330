.position-box {
  border: 1px solid $mainGreen;
  border-radius: 1.5rem;
  padding: 0.5rem;
  color: $mainGreen;
  margin-top: 3.5rem;
  min-width: 280px;

  @include atMedium {
    margin-top: 0;
    min-width: auto;
  }

  &__header {
    margin-top: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__icon {
    margin-right: 0.5rem;
    transform: scale(0.95);
  }

  &__body {
    font-size: 2rem;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-top: 0.5rem;
    text-align: center;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  &__location,
  &__shift {
    display: flex;
    align-items: center;
    color: $textMuted;
    font-size: 16px;

    .icon {
      transform: scale(0.9);
      margin-right: 0.5rem;
    }

    @include atMedium {
      font-size: 14px;

      .icon {
        transform: scale(0.8);
        margin-right: 0.25rem;
      }
    }

    @include atX-Large {
      font-size: 16px;

      .icon {
        transform: scale(0.9);
        margin-right: 0.5rem;
      }
    }
  }
}

.apply-now-btn {
  background-color: $mainGreen;
  color: $mainText;
  font-weight: bold;
  padding: 1rem 3rem;
  cursor: pointer;
  border: none;
  border-radius: 0.5rem;
  display: inline-block;
  margin-top: 1.5rem;
  width: 100%;
  text-align: center;

  @include atMedium {
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover,
  &:active {
    background-color: darken($mainGreen, 4%);
  }
}
