.service-providers-feature {
  &__img {
    width: 100%;
    display: block;
    border-radius: 18px;
    margin: auto;
    max-width: 315px;
    max-height: 680px;

    @include atMedium {
      margin-left: auto;
      margin-right: 1rem;
    }

    @include atLarge {
      margin-right: 60px;
    }
  }

  &__title {
    text-align: center;
    color: $mainText;
    font-weight: 400;
    font-size: 24px;

    @include atMedium {
      text-align: left;
      margin-left: 1rem;
    }

    @include atLarge {
      margin-left: 60px;
    }
  }

  &__text {
    text-align: center;
    font-size: 1rem;
    line-height: 30px;
    max-width: 385px;
    margin: auto;

    @include atMedium {
      text-align: left;
      margin-right: auto;
      margin-left: 1rem;
    }

    @include atLarge {
      margin-left: 60px;
    }
  }

  &--odd {
    @include atMedium {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    .service-providers-feature__img {
      margin: auto;
      max-width: 315px;

      @include atMedium {
        margin-right: auto;
        margin-left: 1rem;
      }

      @include atLarge {
        margin-left: 60px;
      }
    }

    .service-providers-feature__title {
      @include atMedium {
        text-align: right;
        margin-right: 1rem;
      }

      @include atLarge {
        margin-right: 60px;
      }
    }

    .service-providers-feature__text {
      @include atMedium {
        text-align: right;
        margin-left: auto;
        margin-right: 1rem;
      }

      @include atLarge {
        margin-right: 60px;
      }
    }
  }
}
