.delete_account {

    &__page-title {
        color: $mainText;
        text-align: center;
        font-weight: 700;
        font-size: 2.25rem;
        margin: 0;
        margin-top: 7rem;
        margin-bottom: 3rem;
    
        @include atLarge {
            margin-top: 10rem;
        }
    }

    &__page-content {
        color: $mainText;
        

        section {
            padding-bottom: 2rem;
        }
    }

}