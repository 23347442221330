.features {
  .feature {
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 0px 10.3731px 20.7463px 10.3731px rgba(32, 33, 43, 0.078813);
    padding: 1rem;
    min-height: 5rem;

    @include atMedium {
      border-radius: 16px;
    }
  }
}