@mixin atX-Small {
  @media (min-width: 350px) {
    @content;
  }
}

@mixin atSmall {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin untilMedium {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin atMedium {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin atLarge {
  @media (min-width: 992px) {
    @content;
  }
}

 @mixin atX-Large {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin clearfix {
  &::after {
    content: '';
    clear: both;
    display: table;
  }
}