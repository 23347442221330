.language-switcher {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.25rem;

  &:hover {
    text-decoration: underline;
    cursor: pointer;

    .language-text {
      opacity: 0.8;
    }
  }

  .country-flag {
    height: 18px;
    width: 30px;
    border-radius: 5px;
  }

  .language-text {
    margin-top: 2px;
  }
}
