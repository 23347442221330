.badge {
  display: inline-block;
  transition: 0.25s;

  img {
    width: 100%;
    display: block;
  }

  border: 1px solid #e7e7e7;
  padding: 0.8rem 1.5rem;
  width: 9.75rem;
  border-radius: 6.8px;

  &:hover,
  &:focus {
    box-shadow: 0 0.5em 0.5em -0.4em rgb(0 0 0 / 40%);
    transform: translateY(-0.25em);
    border-color: #d7d7d7;
  }

  @include atLarge {
    width: 11.5rem;
    padding: 1rem 1.8rem;
  }
}

.mobile-apps__left .badge {
  &:hover,
  &:focus {
    box-shadow: 0 0.5em 0.5em -0.4em rgb(255 255 255 / 40%);
    transform: translateY(-0.25em);
    border-color: #d7d7d7;
  }
}
