.subscription {
  &__intro {
    text-align: center;
    margin-bottom: 3rem;

    h2 {
      font-size: 1.75rem;
      margin: 0;
      margin-bottom: 0.8rem;
      line-height: 1.4;

      @include atLarge {
        font-size: 2.25rem;
        line-height: 48px;
      }
    }

    p {
      font-size: 1.125rem;
      margin: 0;
      line-height: 1.4;

      @include atLarge {
        font-size: 1.5rem;
        line-height: 48px;
      }
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    margin-bottom: 1.5rem;

    @include atMedium {
      flex: 0 0 50%;
      align-items: flex-start;
      justify-content: flex-start;

      flex-direction: row;
      padding: 1rem;
    }
  }

  &__icon {
    text-align: center;
  }

  &__texts {
    padding: 0.5rem;
    text-align: center;

    @include atMedium {
      text-align: left;
      padding-left: 1rem;
    }

    h5 {
      font-size: 1.125rem;
      margin: 0;

      @include atLarge {
        font-size: 1.5rem;
      }
    }

    p {
      font-size: 0.875rem;
      line-height: 20px;

      @include atLarge {
        font-size: 1rem;
        line-height: 25px;
      }
    }
  }
}
