.box {
  h5 {
    font-size: 2.5rem;
    color: $mainGreen;
    margin: 0;
    line-height: 3rem;

    @include atMedium {
      font-size: 3.5rem;
      line-height: 4rem;
    }
  }

  p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0;
    margin-top: 0.5rem;

    @include atMedium {
      font-size: 1.15rem;
      line-height: 1.5rem;
    }

    @include atLarge {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-top: 1rem;
    }
  }

  &--page2 {
    text-align: center;
    @include atMedium {
      text-align: left;
    }
  }
}