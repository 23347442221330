.card {
  height: 256px;
  width: 90vw;
  background-color: #fff;
  box-shadow: 0 10.1511px 20.3022px 10.1511px rgb(32 33 43 / 8%);
  border-radius: 8px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include atSmall {
    width: 462px;
  }

  .card__stars {
    margin-bottom: 18px;
  }

  .card__stars svg {
    color: #ffda32;
  }

  .card__testimonial {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 1rem;
  }

  .card__person {
    display: flex;
    align-items: center;
  }

  .card__person img {
    display: inline-block;
    width: 35px;
    margin-right: 1rem;
  }
}
