.join-us {
  text-align: center;
  .section__title {
    color: hsl(0, 0%, 31%);
  }

  .section__lead {
    font-size: 1.15rem;
    line-height: 1.4;
    max-width: 850px;
    margin: auto;

    @include atLarge {
      font-size: 1.25rem;
      line-height: 1.6;
    }
  }

  &__item {
    display: block;
    background-color: #454545;
    color: #fff;
    height: 82px;
    display: flex;
    align-items: center;
    width: 320px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    border-radius: 10px;
    overflow: hidden;
    transition: background-color 0.25s;
    &:hover,
    &:focus {
      background-color: #333;
    }

    @include atMedium {
      flex: 0 0 48%;
      align-self: flex-start;
      max-width: 365px;

      margin-left: 0;
      margin-right: 0;
    }
  }

  &__position {
    width: 75%;
    text-align: left;
    padding-left: 1rem;
  }

  &__icon {
    height: 30px;
    width: 30px;
    padding: 0.3rem;
    background-color: #fff;
    border-radius: 50%;
  }

  .col-left {
    a {
      margin-left: auto;
      @include atLarge {
        margin-right: 1.5rem;
      }
    }
  }
  .col-right {
    a {
      @include atLarge {
        margin-left: 1.5rem;
      }
      margin-right: auto;
    }
  }

  .carries-btn {
    display: inline-block;
    background-color: #454545;
    color: #fff;
    padding: 1rem 3.5rem;
    border-radius: 10px;
    overflow: hidden;
    transition: background-color 0.25s;
    &:hover,
    &:focus {
      background-color: #333;
    }
  }
}

.section.join-us {
  padding-top: 1rem;
}
