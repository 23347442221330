.swiper {
  height: 330px;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  @include atSmall {
    margin-left: -1rem;
    width: 80rem;
  }
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  box-sizing: border-box;
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @include atSmall {
    width: 400px;

    justify-content: flex-start;
    padding-left: 1rem;
  }
}
