.row,
.row-extra {
  margin: 0.5rem 0 1rem;
  display: flex;
  min-width: fit-content;
}

.row-extra {
  margin-bottom: 2rem;
  margin-left: 3rem;
}

.row--border {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row > div,
.row-extra > div {
  padding: 0.5rem 0;
}

.name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0.25rem;
  width: 140px;
}

.duration {
  font-size: 12px;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
}

.image img {
  width: auto;
  width: 100px;
  height: 50px;
  border-radius: 10px;
  object-fit: cover;
}

.name-duration {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  min-width: fit-content;
}

.name-duration-image {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.description {
  color: #4f4f4f;
  min-width: 300px;
  width: 220px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  margin-right: 1.5rem;
}

.extra {
  min-width: 100px;
  width: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1.5rem;
}

.extra-button {
  width: 83px;
  height: 36px;
  text-align: center;
  display: inline-block;
  border-radius: 8px;
  font-family: 'Neue Plak Text', sans-serif;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  transition: all 0.25s;
  font-size: 14px;
}

.extra-button--active {
  background-color: #72d66f;
}

.extra-button:hover {
  background-color: #bdbdbd;
}

.price {
  font-weight: bold;
  padding-right: 1rem !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (min-width: 600px) {
  .row {
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }

  .image img {
    width: 125px;
    height: 75px;
    border-radius: 6px;
  }

  .image {
    width: 150px;
  }

  .name {
    font-size: 16px;
    margin-bottom: 0.5rem;
    width: 160px;
  }

  .duration {
    color: #4f4f4f;
    font-size: 14px;
  }

  .name-duration {
    margin-right: 1rem;
    width: 162px;
  }

  .name-duration-image {
    min-width: fit-content;
  }

  .description {
    line-height: 24px;
    font-size: 16px;
    min-width: 350px;
    width: 350px;
    margin-left: 1.5rem;
  }

  .extra-button {
    font-size: 16px;
  }
}
