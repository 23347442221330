.site-cookies {
  position: fixed;
  bottom: -200%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #d5d7da;
  border-radius: 8px;
  font-size: 13px;
  line-height: 18px;
  max-width: 550px;
  animation-name: animCookies;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  z-index: 101;

  display: none;
  align-items: center;
  gap: 10px;

  @include atMedium {
    font-size: 16px;
    line-height: 22px;
    max-width: auto;
  }

  a {
    color: steelblue;

    &:hover {
      text-decoration: underline;
    }
  }

  &__text {
    margin: 0;
    text-align: left;
  }

  &__button {
    width: auto;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: all 0.25s;
    text-align: right;
    align-self: flex-start;

    &:hover {
      background-color: rgb(78, 75, 75);
    }
  }

  &--display-flex {
    display: flex;
  }
}

@keyframes animCookies {
  0% {
    bottom: -200%;
  }

  50% {
    bottom: 2rem;
  }

  100% {
    bottom: 2rem;
  }
}
