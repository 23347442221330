.blog {
  &__page-title {
    color: $mainText;
    text-align: center;
    font-weight: 700;
    font-size: 2.25rem;
    margin: 0;
    margin-top: 7rem;

    @include atLarge {
      margin-top: 10rem;
    }
  }

  &__page-subtitle {
    color: $mainGreen;
    text-align: center;
    font-weight: 400;
    font-size: 1.5rem;
    margin: 0;
    margin-top: 1rem;
  }

  &__cards {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  &__introduction {
    font-weight: bold;
    margin-top: 2rem;
    line-height: 1.6;
    @include atLarge {
      font-size: 18px;
      margin-bottom: 0;
      line-height: 1.8;
      margin-top: 4rem;
    }
  }

  &__be-part-of {
    h3 {
      color: $mainGreen;
      margin-top: 2rem;
      line-height: 1.6;

      @include atLarge {
        font-size: 24px;
        margin-top: 3rem;
      }
    }

    p {
      margin-bottom: 1rem;
      line-height: 1.6;

      @include atLarge {
        font-size: 18px;
        margin-bottom: 1rem;
        line-height: 1.8;
      }
    }

    a {
      font-weight: bold;
      color: $mainGreen;
    }
  }
}
