.privacy-policy-page {
  margin-top: 6rem;
  margin-bottom: 2rem;

  @include atMedium {
    margin-top: 8rem;
    margin-bottom: 5rem;
  }

  h1 {
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.1rem;
    margin-top: 2rem;
    line-height: 1.4;
  }

  h3 {
    font-size: 1.05rem;
    line-height: 1.4;
  }

  h4 {
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    @include atMedium {
      margin-top: 1rem;
    }
  }

  em {
    font-weight: bold;
  }

  p {
    line-height: 1.4;
  }

  ol {
    counter-reset: list;
    padding-left: 0.5rem;

    & > li {
      list-style: none;
      margin-bottom: 0.5rem;
      font-size: 1rem;
      line-height: 1.4;
      display: flex;

      &::before {
        content: counter(list, lower-alpha) ') ';
        counter-increment: list;
        margin-right: 0.5rem;
      }
    }
  }

  .border {
    border: 1px solid #000;
    padding: 0.5rem;
  }

  table {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 100%;
    font-size: 0.8rem;

    @include atMedium {
      table-layout: fixed;
    }

    @include atMedium {
      font-size: 1rem;
    }

    thead {
      text-align: left;
    }

    td {
      vertical-align: top;
      padding-right: 0.5rem;
      @include atMedium {
        max-width: 30%;
      }
    }
  }
}
