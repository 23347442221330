.get-in-touch-modified {
  background-color: #1f2125;
  color: #fff;

  .message-has-been-sent {
    color: #66bb6a;
    font-size: 1.5rem;
    text-align: center;

    &.error {
      color: red;
    }
  }

  form {
    margin-top: 3rem;

    @include atMedium {
      margin-top: 3.5rem;
    }
  }

  h2 {
    font-size: 2.25rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  .btn:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  input,
  textarea {
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem;
    border: none;
    background-color: #313236;
    border: 1px solid transparent;
    border: 1px solid transparent;

    color: #e7e7e7;
    position: relative;
    z-index: 2;
    overflow: hidden;

    @include atMedium {
      margin-bottom: 0.5rem;
    }

    &.error {
      border-color: red;
    }
  }

  ::placeholder,
  ::-ms-input-placeholder {
    color: #e7e7e7;
  }

  textarea {
    height: 140px;
  }

  .form-group {
    position: relative;
    margin-bottom: 2.5rem;

    &--terms {
      margin-bottom: 3rem;
      height: 1.875rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      @include atMedium {
        margin-bottom: 2rem;
        justify-content: flex-end;
      }

      p {
        margin-left: 2.5rem;
        margin-top: 25px;
        @include atLarge {
          margin-top: 24px;
        }

        a {
          color: $mainGreen;
        }
      }

      .round {
        position: relative;
      }

      .round label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: pointer;
        height: 28px;
        left: 0;
        position: absolute;
        top: 0;
        width: 28px;
      }

      &.error {
        .round label {
          border-color: red;
        }
      }

      .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: '';
        height: 6px;
        left: 7px;
        opacity: 0;
        position: absolute;
        top: 8px;
        transform: rotate(-45deg);
        width: 12px;
      }

      .round input[type='checkbox'] {
        visibility: hidden;
      }

      .round input[type='checkbox']:checked + label {
        background-color: #66bb6a;
        border-color: #66bb6a;
      }

      .round input[type='checkbox']:checked + label:after {
        opacity: 1;
      }
    }
  }

  .liveValidateMessage {
    top: -24px;
    left: -12px;
    position: absolute;
    z-index: 1;
    padding-top: 6px;
    padding-bottom: 16px;
    padding-left: 0.8rem;
    padding-right: 0;
    font-size: 0.8rem;
    color: red;

    @include atMedium {
      top: auto;
      bottom: 46px;
    }
  }

  textarea + .liveValidateMessage {
    @include atMedium {
      top: -22px;
    }

    @include atLarge {
      top: -22px;
    }
  }

  .liveValidateMessage--visible {
    opacity: 1;
    transform: translateY(0);
  }

  .liveValidateMessage-enter {
    opacity: 0;
    transform: translateY(100%);
  }

  .liveValidateMessage-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: 0.33s opacity ease-in-out,
      0.33s transform ease-in-out;
  }

  .liveValidateMessage-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .liveValidateMessage-exit-active {
    opacity: 0;
    transform: translateY(100%);
    transition: 0.33s opacity ease-in-out,
      0.33s transform ease-in-out;
  }

  // Select topic type getintouch
  .select-topic-type {
    position: relative;
    .errorMessage {
      position: absolute;
      color: red;
      font-size: 0.8rem;
      bottom: 15px;
      right: 50px;
      display: none;
      &.show {
        display: block;
        @include atLarge {
          display: none;
        }
      }
    }

    &.error {
      border-color: red;
    }

    input[type='checkbox'] + label {
      display: block;
      cursor: pointer;

      @include atLarge {
        font-size: 0.8rem;
      }
    }

    input[type='checkbox'] {
      display: none;
    }

    input[type='checkbox'] + label:before {
      content: '\2714';
      border: 1px solid #e7e7e7;
      border-radius: 3px;
      width: 19px;
      height: 19px;
      display: inline-block;
      margin-right: 0.5rem;
      padding-left: 2px;
      color: transparent;
      vertical-align: bottom;
      @include atLarge {
        font-size: 0.8rem;
        padding-left: 3px;
        padding-top: 2px;
      }
    }

    input[type='checkbox']:checked + label:before {
      background-color: $mainGreen;
      border-color: $mainGreen;
      color: #fff;
    }
  }

  .select-topic-type {
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid transparent;
    box-shadow: 0px 4px 4px rgba(31, 33, 37, 0.2);
    background: #313236;
    margin-bottom: 2.75rem;
    margin-top: 0.5rem;
    height: 51px;
    transition: all 0.25s ease-in-out;

    @include atLarge {
      position: absolute;
      z-index: 10;
      margin-bottom: 0;
      margin-top: 0;
    }

    .topic-type {
      cursor: pointer;
      font-size: 0.9rem;
      color: #797575;
      padding: 1rem;
      margin: 0;
      padding-bottom: 1rem;
      font-size: 1rem;

      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        display: block;
        transition: all 0.25s ease-in-out;

        width: 18px;
      }
    }
  }

  .select-topic-type.show {
    height: 470px;

    @include atLarge {
      height: 342px;
    }

    .topic-type {
      border-bottom: 1px solid rgba(31, 33, 37, 0.2);
    }

    img {
      transform: rotate(180deg);
    }
  }

  .checkbox-container {
    padding: 1rem;
    display: flex;
    align-items: flex-end;

    @include atLarge {
      padding-top: 1rem;
      padding-bottom: 0;
    }
  }

  .checkbox-container:nth-of-type(5) {
    label::before {
      float: left;
      display: inline-block;
    }
    span {
      line-height: 1.2;
      float: right;

      width: 89.8%;

      @media (min-width: 375px) {
        width: 90.85%;
      }

      @media (min-width: 400px) {
        width: 91%;
      }

      @media (min-width: 420px) {
        width: 91.5%;
      }

      @media (min-width: 450px) {
        width: 92.75%;
      }

      @include atLarge {
        width: 83%;
      }

      @include atX-Large {
        width: 87%;
      }
    }

    @include clearfix();
  }
}
