.container {
  &--large {
    max-width: 87.5rem;
  }

  &--slider {
    position: relative;

    @media only screen and (max-width: 600px) {
      padding: 0;
    }
  }

  &--special {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @include atSmall {
      max-width: 540px;
    }

    @include atMedium {
      width: 100%;
      max-width: 1140px;
    }
  }
}
