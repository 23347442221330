.repair-shop-single {
  &__header {
    overflow: hidden;

    margin-bottom: 5rem;

    @include atMedium {
      min-height: 300px;
    }

    .shop-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      text-align: center;

      @include atMedium {
        text-align: left;
      }
    }

    h1 {
      font-size: 30px;
      margin: 0;
      line-height: 1.2;
      margin-bottom: 0.5rem;
      white-space: nowrap;

      @include atMedium {
        font-size: 32px;
      }

      @include atX-Large {
        font-size: 42px;
      }
    }

    svg {
      width: 1.25rem;
      margin-right: 0.25rem;
      font-size: 20px;
    }

    h2 {
      font-size: 17px;
      margin: 0;
      margin-bottom: 1rem;
      white-space: nowrap;
    }

    .rp-img-container {
      max-width: 100%;
      margin: auto;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 1rem;

      @include atMedium {
        max-width: 100%;
        margin-left: auto;
        margin-right: 0;
      }

      img {
        width: 100%;
        display: block;
      }
    }
  }

  &__introduction {
    .rp-intro {
      margin: 0;
      font-size: 40px;
      margin-bottom: 1rem;

      @include atLarge {
        font-size: 48px;
        margin-bottom: 2rem;
      }
    }

    .opening-title {
      font-weight: bold;
      font-size: 28px;
      margin: 0;
      margin-bottom: 1.5rem;
      @include atLarge {
        font-size: 32px;
      }
    }

    .opening {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;

      .d {
        margin-right: 2rem;
      }

      .o {
        margin-right: 1rem;
      }
      .c {
        margin-left: 1rem;
      }

      .oc {
        @include atMedium {
          margin-right: 2rem;
        }

        @include atX-Large {
          margin-right: 8rem;
        }
      }
    }

    .rp-location-title {
      font-weight: bold;
      margin: 0;
      margin-bottom: 1.5rem;
      font-size: 28px;

      @include atLarge {
        font-size: 32px;
      }

      .small {
        font-size: 16px;
        text-decoration: underline;
        margin-left: 1rem;
        vertical-align: middle;

        @include atMedium {
          margin-left: 3rem;
        }
      }
    }
  }

  &__services {
    margin-top: 9rem;
    @include atLarge {
      margin-top: 9rem;
    }

    .rp-services {
      margin: 0;
      font-size: 40px;
      margin-bottom: 1rem;

      @include atLarge {
        font-size: 48px;
      }
    }

    .services-category-list {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 3rem;

      margin-bottom: 2rem;
    }

    .services-category {
      font-size: 18px;
      padding: 0.25rem;
      color: $textMuted;
      cursor: pointer;
      border: 1px solid $textMuted;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      border-radius: 10px;
      font-weight: 300;
      transition: all 0.25s ease-in-out;

      @include atMedium {
        font-size: 20px;
        padding: 0.3rem;
      }
      @include atLarge {
        font-size: 24px;
        padding: 0.5rem;
        margin-right: 0.8rem;
        margin-bottom: 0.8rem;
      }

      &.selected,
      &:hover {
        border-color: #000;
        box-shadow: 3px 3px 8px rgb(0 0 0 / 0.4);
        color: #000;
      }

      &.selected {
        transform: scale(1.025);
      }

      @include atLarge {
        &.selected {
          transform: scale(1.05);
        }
      }
    }

    .services-list {
      overflow: auto;
    }

    .services-list__item {
      display: flex;
      justify-content: space-between;
      border-radius: 5px;
      min-width: 600px;
      text-align: left;
      transition: background-color 0.3s;
      padding: 5px 0;

      margin-bottom: 1rem;

      @include atLarge {
        margin-bottom: 2rem;
      }

      &:hover {
        background-color: $mainGreen;
      }

      .name-and-duration {
        display: flex;
        flex-direction: column;
        width: fit-content;
        margin-right: 0.5rem;
        padding: 0.25rem;

        &__name {
          font-size: 24px;
          margin-bottom: 0.5rem;
          font-weight: 600;

          font-size: 18px;
        }

        &__duration {
          font-size: 20px;
          color: #4f4f4f;

          font-size: 14px;
        }

        min-width: 200px;
        width: 200px;

        @include atLarge {
          min-width: 300px;
          width: 300px;
        }
      }

      .item-description {
        font-size: 20px;
        line-height: 28px;
        color: #4f4f4f;
        margin-right: 0.5rem;
        text-align: left;
        flex: 1;
        height: 100%;
        vertical-align: top;

        padding-right: 0.5rem;

        font-size: 18px;
      }

      .item-price {
        font-weight: 600;
        font-size: 20px;
        width: fit-content;
        padding: 0.25rem;

        font-size: 18px;
      }
    }
  }
}

iframe,
.leafletContainer,
.map-container {
  width: 100%;
  border-radius: 1rem;
  height: 100%;
  min-height: 260px;
}

.request-failed {
  transform: translateY(-50%);
  position: relative;
  left: 50%;
  margin-top: 35vh;
  margin-bottom: 4rem;
}
