.backdrop {
  transition: 0.2s;
  opacity: 0;
  display: none;
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -9999;

  &--show {
    opacity: 1;
  }

  &--z-index-1000 {
    z-index: 1000;
  }

  &--display {
    display: block;
  }

  @include atMedium {
    display: none;
  }
}
