.dtr {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 30px;

  @media only screen and (min-width: 335px) {
    font-size: 22px;
    line-height: 30px;
  }
  @media only screen and (min-width: 355px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 35px;
  }
  @media only screen and (min-width: 375px) {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 38px;
  }
  @media only screen and (min-width: 395px) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 40px;
  }
  @media only screen and (min-width: 650px) {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 40px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 55px;
  }

  &__start,
  &__end {
    display: block;
    text-align: center;
  }

  &__texts {
    overflow: hidden;
    background-color: $mainGreen;
    position: relative;
    width: 290px;
    margin: auto;

    height: 28px;
    @media only screen and (min-width: 335px) {
      height: 30px;
    }
    @media only screen and (min-width: 355px) {
      height: 32px;
    }
    @media only screen and (min-width: 375px) {
      height: 34px;
    }
    @media only screen and (min-width: 395px) {
      height: 36px;
    }
    @media only screen and (min-width: 650px) {
      height: 38px;
      width: 295px;
    }
    @media only screen and (min-width: 992px) {
      height: 48px;
      width: 400px;
    }

    ul {
      margin: 0;
      padding: 0;
      width: 100%;
      list-style: none;
      position: absolute;
      height: 100%;
      animation-name: animHeading;
      animation-duration: 8s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    li {
      text-align: center;
    }
  }
}

@keyframes animHeading {
  0% {
    top: 0;
  }

  20% {
    top: 0;
  }

  25% {
    top: -100%;
  }

  45% {
    top: -100%;
  }

  50% {
    top: -200%;
  }

  70% {
    top: -200%;
  }

  75% {
    top: -300%;
  }

  100% {
    top: -300%;
  }
}
